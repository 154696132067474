import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
//import * as token from "../../shared/constants/constants";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchContractList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100",
      patientNameOrId: payload.searchTxt,
      medcaseStatus: payload.medcaseStatus,
      contractStatus: payload.contractStatus,
      pgtaStatus: payload.pgtaStatus,
      paymentStatus: payload.paymentStatus,

      fromDate: payload.fromDate,
      toDate: payload.toDate
    };
    const requestBody = createRequestBody("get", `/contract`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    console.log(response);
    yield put({
      type: actionTypes.CONTRACT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.CONTRACT_LIST_FAILED, errorMsg: error.message });
    }
  }
}

function* getContractListSaga() {
  yield takeEvery(actionTypes.CONTRACT_LIST_REQUESTED, fetchContractList);
}

function* fetchContractDocumentDownload(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/contract/${payload.contractId}/documentUrl`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    console.log(response);
    yield put({
      type: actionTypes.CONTRACT_DOCUMENT_DOWNLOAD_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CONTRACT_DOCUMENT_DOWNLOAD_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}

function* getContractDocumentDownloadSaga() {
  yield takeEvery(actionTypes.CONTRACT_DOCUMENT_DOWNLOAD_REQUESTED, fetchContractDocumentDownload);
}

export default function* contractSaga() {
  yield all([getContractListSaga(), getContractDocumentDownloadSaga()]);
}
