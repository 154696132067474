import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import * as actionType from "../actions";
// import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import moment from "moment";
import { useParams } from "react-router-dom";
import PopupModal from "../../shared/components/PopupModal";

const ClinicalComments = ({ medcaseType, loading, clinicalList = [], actions }) => {
  const { id } = useParams();
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [expanded, setExpanded] = useState(false);
  const [fullComment, setFullComment] = useState("");
  const [isViewMoreModal, setIsViewMoreModal] = useState(false);

  const rowsData = clinicalList.map((item, index) => {
    item.id = index;
    return item;
  });

  const handleViewMore = (comment) => {
    setFullComment(comment);
    setIsViewMoreModal(true);
  };

  const columns = [
    {
      field: "updatedDate",
      headerName: "DATE",
      // width: 100,
      flex: 0.5,
      renderCell: ({ row }) => {
        return moment(row.updatedDate).format("L");
      }
    },
    {
      field: "referenceNo",
      headerName: "CYCLE NO.",
      // width: 50,
      editable: false,
      flex: 0.5
    },
    // {
    //   field: "important",
    //   headerName: "MARK IMP",
    //   // width: 50,
    //   editable: false,
    //   flex: 0.5
    // },
    {
      field: "updatedBy",
      headerName: "USER",
      // width: 100,
      editable: false,
      flex: 0.5
    },
    {
      field: "commentType",
      headerName: "COMMENT TYPE",
      // width: 100,
      editable: false,
      flex: 0.5
    },
    {
      field: "comment",
      headerName: "COMMENT",
      // width: 50,
      editable: false,
      flex: 4,
      renderCell: ({ row }) => (
        <div className="textarea-wrap">
          <Typography className="textarea-wrap" color={row.important === true ? "red" : ""}>
            {row.comment.length > 180 ? (
              <>
                {row.comment.substring(0, 180)}...
                <Button onClick={() => handleViewMore(row.comment)}>View More</Button>
              </>
            ) : (
              row.comment
            )}
          </Typography>
        </div>
      )
    }
  ];

  useEffect(() => {
    actions.getComment({ medcaseId: id, commentType: "Clinical" });
  }, []);

  const handleChange = () => {
    if (expanded === true) {
      setExpanded(false);
    }
    if (expanded === false) {
      setExpanded(true);
    }
  };

  const closeViewMoreModal = () => {
    setIsViewMoreModal(false);
  };

  return (
    <>
      <Box className="m-8">
        <Accordion
          key="programCycles"
          onChange={handleChange}
          expanded={expanded}
          defaultExpanded={false}
          // disabled={medcaseType == "PGTA"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              flexDirection: "row-reverse"
            }}>
            <Typography>Clinical Comment ({rowsData.length})</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%"
              // minHeight: rows ? (rows.length > 2 ? "650pX" : "650px") : "650px"
            }}>
            <DataGridPremiumComp
              rows={medcaseType == "PGTA" ? rowsData : rowsData}
              columns={columns}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
              autoHeight={true}
              getRowClassName={(params) => {
                return params.row.important == true ? "imp-trans" : "";
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* <WarnningMessageModal
        open={isWarningOpen}
        message={warningMessage}
        closeModal={closeWarningModal}
      /> */}
      <PopupModal
        open={isViewMoreModal}
        title="Full Comment"
        content={<Typography>{fullComment}</Typography>}
        handleClose={closeViewMoreModal}
      />
    </>
  );
};

const mapStateToProps = ({ commentReducer }) => {
  return {
    loading: commentReducer.loading,
    clinicalList: commentReducer.clinicalList
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getComment: (medCaseId) => {
      dispatch(actionType.getComment(medCaseId));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connect(mapStateToProps, mapDispatchToProps)(ClinicalComments));
