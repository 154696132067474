import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* editEnrollment(action) {
  const payload = action.payload;
  try {
    let dateParam = { date: payload.payload.medcaseDate };
    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.payload.medcaseId}/enroll`,
      "",
      dateParam
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_ENROLL_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_ENROLL_FAIL,
        message: error.message
      });
    }
  }
}
function* editEnrollmentSaga() {
  yield takeEvery(actionTypes.EDIT_ENROLL_REQUESTED, editEnrollment);
}

export default function* enrollSaga() {
  yield all([editEnrollmentSaga()]);
}
