import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import {
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import MessageModal from "../../shared/components/MessageModal";
import ReimbursementDetails from "./ReimbursementDetails";
import PricingModal from "./modals/PricingModal";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import moment from "moment";

const ManualReview = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [selectedPRId, setSelectedPRId] = useState(0);
  const [selectedPR, setSelectedPR] = useState({});
  const [openPricingModal, setIsOpenPricingModal] = useState(false);
  const [selectedPRs, setSelectedPRs] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [approvedModal, setApprovedModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [modelMsg, setModelMsg] = useState("");
  const [rows, setRows] = useState([]);

  const updateSelectedPR = (selectedRowData) => {
    //console.log("selected PR", selectedRowData);
    setSelectedPRs([...selectedRowData]);
  };

  const columns = [
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      width: 90,
      editable: false
    },
    {
      field: "clinicalTreatmentNo",
      headerName: "CYCLE NO.",
      width: 80,
      editable: false
    },
    {
      field: "patientFirstName",
      headerName: "FIRST NAME",
      width: 100,
      editable: false
    },
    {
      field: "patientLastName",
      headerName: "LAST NAME",
      width: 100,
      editable: false
    },
    {
      field: "approvedReimbursementAmount",
      headerName: "AMOUNT",
      width: 75,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.approvedReimbursementAmount);
      }
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 130,
      editable: false
    },
    {
      field: "medcaseStatus",
      headerName: "MEDCASE STATUS",
      width: 80,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 80,
      editable: false
    },
    {
      field: "transactionTypeDesc",
      headerName: "TRANSACTION TYPE",
      width: 140,
      editable: false
    },
    {
      field: "outcomeStatus",
      headerName: "OUTCOME",
      width: 130,
      editable: false
    },
    {
      field: "embryosRemaining",
      headerName: "EMBRYOS REMAINING",
      width: 100,
      editable: false
    },
    {
      field: "normalembryosRemaining",
      headerName: "NORMAL EMBRYOS REMAINING",
      width: 100,
      editable: false
    },
    {
      field: "requestedTreatmentPlan",
      headerName: "AUTH SERVICE",
      width: 85,
      editable: false
    },
    {
      field: "creationDate",
      headerName: "CREATED DATE",
      width: 150,
      editable: false,
      renderCell: ({ row }) =>
        row.creationDate ? moment(row.creationDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "createdBy",
      headerName: "CREATED USER",
      width: 150,
      editable: false
    },
    {
      field: "approvedDatez",
      headerName: "APPROVED DATE",
      width: 150,
      editable: false
    },
    {
      field: "approvedUserz",
      headerName: "APPROVED USER",
      width: 150,
      editable: false
    },
    {
      field: "exportedDatez",
      headerName: "EXPORTED DATE",
      width: 150,
      editable: false
    },
    {
      field: "exportedUserz",
      headerName: "EXPORTED USER",
      width: 150,
      editable: false
    },
    {
      field: "rejectedDatez",
      headerName: "REJECTED DATE",
      width: 150,
      editable: false
    },
    {
      field: "rejectedUserz",
      headerName: "REJECTED USER",
      width: 150,
      editable: false
    }
  ];

  const {
    providerReimbursementList = [],
    actions,
    addUnApproved,
    editUnApproved,
    loading,
    errorMsg,
    successRunPR,
    successEditPR,
    newPrList,
    successCheck
  } = props;

  const initFetch = useCallback(() => {}, [addUnApproved, editUnApproved]);

  useEffect(() => {
    actions.getProviderReimbursement({ status: "Unapproved" });
  }, []);

  const modifyCol = (rows) => {
    return rows.map((row) => {
      return {
        ...row,
        approvedUser: row.updatedBy,
        exportedUser: row.updatedBy,
        creationDate: row.creationDate ? moment(row.creationDate).format("MM/DD/YYYY") : null,
        exportedDate: row.exportedDate ? moment(row.exportedDate).format("MM/DD/YYYY") : null
      };
    });
  };

  const rows_modify = modifyCol(
    providerReimbursementList.filter((item, index) => {
      if (
        item.initReimbursementAmount == 0.0 ||
        item.isManualReview === true ||
        item.isManualEntry === true
      ) {
        item.id = index;
        return item;
      }
    })
  );

  useEffect(() => {
    setRows(rows_modify);
  }, [providerReimbursementList]);

  useEffect(() => {
    if (successEditPR) {
      setIsMessageModal(true);
    }
    if (successRunPR) {
      setIsMessageModal(true);
    }

    if (providerReimbursementList.length == 0 || addUnApproved || editUnApproved) {
      initFetch();
    }
    return () => {
      actions.resetProviderReimbursementList();
    };
  }, [addUnApproved, editUnApproved, errorMsg, successRunPR, successEditPR]);

  useEffect(() => {
    if (Object.entries(selectedPR).length > 0) {
      let payload = {
        medcaseId: selectedPR.medcaseId,
        clinicalTreatmentNo: selectedPR.clinicalTreatmentNo
      };
      actions.createProgramCycle(payload);
    }
  }, [selectedPRId, providerReimbursementList]);

  useEffect(() => {
    if (successCheck === true) {
      if (approvedModal) {
        setModelMsg("Approved successfully.");
      } else if (rejectModal) {
        setModelMsg("Rejected successfully.");
      }
      setSelectionModel([]);
      setSelectedPRs([]);
      setSelectedPRId(0);
      setSelectedPR({});
    }
  }, [successCheck]);

  const UpdatedNewPrList = () => {
    // Update selectPrIdList with new values from dataList
    selectedPRs.forEach((selectItem) => {
      const matchedItem = rows.find((data) => data.prId === selectItem.prId);
      if (matchedItem) {
        Object.assign(selectItem, matchedItem); // Update in place
      }
    });
    return selectedPRs;
  };

  const addApprove = () => {
    let updatedselectedPRs = UpdatedNewPrList();
    updatedselectedPRs.map((item) => {
      item.reimbursementStatus = "Approved";
      delete item.id;
      delete item.approvedUser;
      delete item.exportedUser;
      return item;
    });

    const payload = { value: updatedselectedPRs };
    console.log("payload", payload);
    actions.addAproovedReimbursement(payload);
    setApprovedModal(true);
  };
  const addReject = () => {
    let updatedselectedPRs = UpdatedNewPrList();
    updatedselectedPRs.map((item) => {
      item.reimbursementStatus = "Rejected";
      delete item.id;
      delete item.approvedUser;
      delete item.exportedUser;
      return item;
    });
    const payload = { value: updatedselectedPRs };
    actions.addAproovedReimbursement(payload);
    setRejectModal(true);
  };
  const closeMessageModal = () => {
    actions.getProviderReimbursement({ status: "Unapproved" });
    setIsMessageModal(false);
    setApprovedModal(false);
    setRejectModal(false);
    setModelMsg("");
    actions.resetMessage();
  };
  const runPR = () => {
    setIsMessageModal(true);
    actions.runPR();
  };
  const refresh = () => {
    actions.getProviderReimbursement({ status: "Unapproved" });
  };
  const openPricing = () => {
    setIsOpenPricingModal(true);
  };
  const closePricingModal = () => {
    setIsOpenPricingModal(false);
  };
  // const disablePricing = () => {
  //   if (selectedPRId != null) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  const getRowStyle = (params) => {
    const {
      clinicalTreatmentNo,
      normalembryosRemaining,
      //programName,
      isManualReview,
      outcomeStatus,
      noOfCycle
    } = params.row;

    const remainingEmbryos = parseInt(normalembryosRemaining, 10);
    const programCycle = clinicalTreatmentNo ? parseFloat(clinicalTreatmentNo) : null;

    // Condition 1: Specific outcome status
    const condition1 = outcomeStatus === "Pregnant-";

    // Extract the cycle number (handles "2" and "2+")

    // const cycleNumberMatch =
    //(programName && programName.match(/(?:[^\d\w]|^)(\d+\+?)(?=\s|$)/)) || null;

    // If a match is found, remove '+' and return as a number

    const programCycleNumber = noOfCycle ? parseFloat(noOfCycle) : null;

    // Dynamic condition for any cycle program
    const dynamicCycleCondition =
      programCycleNumber !== null && programCycle >= programCycleNumber && remainingEmbryos === 0;

    // Combine all green conditions
    const anyGreenCondition = condition1 || dynamicCycleCondition;

    // Define the inline styles
    if (anyGreenCondition) {
      return "imp-green";
    }

    if (isManualReview) {
      return "imp-red";
    }

    return ""; // Default style
  };

  const getRowsFromCurrentPage = ({ apiRef }) =>
    gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();
    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
    return (
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack
          direction="row"
          className="grid-header"
          sx={{ display: "flex", flexDirection: "row", marginBottom: "5px", gap: 5 }}>
          <Typography>ManualReview List</Typography>
          <Button variant="contained" className="btn-primary" onClick={runPR}>
            Run PR
          </Button>
          <Button variant="contained" className="btn-primary" onClick={refresh}>
            Refresh
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={openPricing}
            disabled={selectionModel.length > 0 ? false : true}>
            Pricing
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={addApprove}
            disabled={selectionModel.length > 0 ? false : true}>
            Approve
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={addReject}
            disabled={selectionModel.length > 0 ? false : true}>
            Reject
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}>
            Send To Excel
          </Button>
        </Stack>
      </Box>
    );
  };
  return (
    <>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          initialState={{
            sorting: {
              sortModel: [{ field: "creationDate", sort: "desc" }]
            }
          }}
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          checkboxSelection
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onRowSelectionModelChange={(ids) => {
            console.log("ids:", ids);
            setSelectionModel(ids);
            const selectedIDs = new Set(ids);
            console.log("selectedIDs:", selectedIDs);
            const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
            console.log("selectedRowData:", selectedRowData);
            updateSelectedPR(selectedRowData);
          }}
          getRowClassName={(params) => {
            return getRowStyle(params);
          }}
          onCellClick={(e) => {
            console.log("e:", e);
            setSelectedPRId(e?.row?.medcaseId);
            setSelectedPR(e?.row);
          }}
          rowSelectionModel={selectionModel}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          components={<CustomToolbar />}
        />
      </Box>
      {openPricingModal ? (
        <PricingModal
          open={openPricingModal}
          close={closePricingModal}
          newPrList={newPrList}
          selectedPR={selectedPR}
        />
      ) : (
        ""
      )}
      {(modelMsg.length || errorMsg) && (
        <MessageModal
          open={approvedModal}
          message={modelMsg}
          error={errorMsg}
          closeModal={closeMessageModal}
        />
      )}
      {(modelMsg.length || errorMsg) && (
        <MessageModal
          open={rejectModal}
          message={modelMsg}
          error={errorMsg}
          closeModal={closeMessageModal}
        />
      )}
      <MessageModal
        open={isMessageModal}
        message={successEditPR || successRunPR}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <ReimbursementDetails
        selectedPRId={selectedPRId}
        selectedPR={selectedPR}
        tabName="Manual Review"
        compCheck={true}
        isManualReviewRequired={newPrList && newPrList?.isManualReview}
      />
    </>
  );
};
const mapStateToProps = ({ providerReimbursementReducer, newPrReducer }) => {
  return {
    loading: providerReimbursementReducer.loading,
    providerReimbursementList: providerReimbursementReducer.providerReimbursementList,
    errorMsg: providerReimbursementReducer.errorMsg,
    successCheck: providerReimbursementReducer.successCheck,
    newPrList: newPrReducer.newPrList,
    successRunPR: providerReimbursementReducer.successRunPR,
    successEditPR: providerReimbursementReducer.successEditPR
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProviderReimbursement: (payload) => {
      dispatch(actionType.getProviderReimbursement(payload));
    },
    addAproovedReimbursement: (payload) => {
      dispatch(actionType.addAproovedReimbursement(payload));
    },
    resetProviderReimbursementList: () => {
      dispatch(actionType.resetProviderReimbursementList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    runPR: () => {
      dispatch(actionType.runPR());
    },
    createProgramCycle: (payload) => {
      dispatch(actionType.createProgramCycle(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ManualReview);
