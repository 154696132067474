import * as actionType from "../actions/ActionTypes";

const initialState = {
  fapList: [],
  clinicalData: {},
  editProgramData: {},
  addProgramData: {},
  editProgramLoading: false,
  patientMedcaseInfo: {},
  medcaseInfoLoading: false,
  loading: false,
  error: null,
  success: null,
  getProgramLoading: false,
  patientUser: {}
};

const fapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FAP_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FAP_LIST_SUCCESS: {
      return {
        ...state,
        fapList: action.payload,
        loading: false
      };
    }
    case actionType.FAP_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.SET_PATIENT_DETAILS: {
      return {
        ...state,
        patientUser: action.payload,
        loading: false
      };
    }
    case actionType.RESET_FAP_LIST: {
      return {
        ...state,
        fapList: [],
        loading: false,
        error: null
      };
    }
    case actionType.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_SUCCESS: {
      return {
        ...state,
        // adjustments: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDITED_AUTH_DATA_SAVE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDITED_AUTH_DETAILS_SAVE_SUCCESS: {
      return {
        ...state,
        // adjustments: action.payload,
        loading: false,
        successAuthMSG: action.successMsg
      };
    }
    case actionType.EDITED_AUTH_DETAILS_SAVE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.CLINICAL_TREATMENT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CLINICAL_TREATMENT_DATA_SUCCESS: {
      return {
        ...state,
        clinicalData: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.CLINICAL_TREATMENT_DATA_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_PROGRAM_FAP_VISIBILITY_REQUESTED: {
      return {
        ...state,
        editProgramLoading: true,
        getProgramLoading: true
      };
    }
    case actionType.EDIT_PROGRAM_FAP_VISIBILITY_SUCCESS: {
      return {
        ...state,
        editProgramData: action.payload,
        editProgramLoading: false,
        getProgramLoading: false
      };
    }
    case actionType.EDIT_PROGRAM_FAP_VISIBILITY_FAILED: {
      return {
        ...state,
        error: action.message,
        editProgramLoading: false,
        getProgramLoading: false
      };
    }
    case actionType.ADD_FAP_VISIBILITY_REQUESTED: {
      return {
        ...state,
        editProgramLoading: true
      };
    }
    case actionType.ADD_FAP_VISIBILITY_SUCCESS: {
      return {
        ...state,
        addProgramData: action.payload,
        editProgramLoading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_FAP_VISIBILITY_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        editProgramLoading: false
      };
    }
    case actionType.RESET_FAP_VISIBILITY: {
      return {
        ...state,
        addProgramData: {},
        editProgramData: {},
        loading: null
      };
    }
    case actionType.GET_TREATMENT_PLAN_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_TREATMENT_PLAN_LIST_SUCCESS: {
      return {
        ...state,
        treatmentPlanList: action.payload,
        loading: false
      };
    }
    case actionType.GET_TREATMENT_PLAN_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.GET_MEDCASE_PATIENT_INFO_REQUESTED: {
      return {
        ...state,
        medcaseInfoLoading: true
      };
    }
    case actionType.GET_MEDCASE_PATIENT_INFO_SUCCESS: {
      return {
        ...state,
        patientMedcaseInfo: action.payload,
        medcaseInfoLoading: false
      };
    }
    case actionType.GET_MEDCASE_PATIENT_INFO_FAILED: {
      return {
        ...state,
        error: action.message,
        medcaseInfoLoading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: action.error,
        success: action.success
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default fapReducer;
