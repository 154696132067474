import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import { refundDetailsConfig } from "../../shared/constants/renderConfigs";
import moment from "moment";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import MessageModal from "../../shared/components/MessageModal";
import { useParams } from "react-router-dom";
import LoadingModal from "./modals/loadingModal";
import isAccess from "../../shared/components/CheckUserRights";

const MedcaseRefund = (props) => {
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const { manualRefundList = [], actions, loading, success, refundError, programName } = props;

  const isPNFT = isAccess("pnft");
  const isEFT = isAccess("eft");

  console.log("zhr manualRefundList", manualRefundList);
  console.log("zhr programName", programName);

  // const navigate = useNavigate();
  const removeParam = () => {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const searchParams = url.searchParams;
    if (searchParams.has("cycle")) {
      searchParams.delete("cycle");
      const newUrl = `${path}${searchParams.toString() ? `?${searchParams.toString()}` : ""}`;
      console.log("newUrl", newUrl);
      // navigate(newUrl);
      window.location.href = newUrl;
    }
  };

  const formData = {
    amount: "",
    transactionType: "",
    transactionDate: new Date(),
    medcaseId: id,
    recordedDate: new Date(),
    comment: ""
  };

  const closeWarningModal = () => {
    setIsWarningOpen(false);
    setWarningMessage("");
    actions.resetManualRefundMessage();
  };
  useEffect(() => {
    if (success) {
      setOpen(true);
      // closeModal();
    } else if (refundError) {
      setOpen(false);
      setIsWarningOpen(true);
      setWarningMessage(refundError);
      closeModal();
    }
  }, [success, refundError]);
  useEffect(() => {
    if (manualRefundList.length > 0) {
      // actions.getTransactionLog({ medcaseId: values.medcaseId });
      actions.getTransactionLog({ medcaseId: id });
    }
  }, [manualRefundList]);

  useEffect(() => {
    actions.getProgramName({ medcaseId: id });
  }, []);

  const [values, setValues] = useState(formData);
  const closeModal = () => {
    props.close();
    setValues(formData);
  };
  const saveRefund = (event) => {
    event.preventDefault();
    // remove cycle form uri
    removeParam();
    values.recordedDate = values.recordedDate
      ? moment(values.recordedDate).format("YYYY-MM-DD")
      : null;
    values.transactionDate = values.transactionDate
      ? moment(values.transactionDate).format("YYYY-MM-DD")
      : "";
    if (values.isImportant === "on") {
      values.isImportant = true;
    } else {
      values.isImportant = false;
    }
    values.amount = parseFloat(values.amount);
    if (props.netProgramFee < values.amount) {
      setOpen(false);
      setIsWarningOpen(true);
      setWarningMessage(
        "Refund Amount should not be more than Net Program Fee. Kindly check the Amount entered"
      );
    } else {
      values.isPNFT = isPNFT;
      values.isEFT = isEFT;
      let payload = { medcaseId: id, value: values };
      actions.setManualRefundList(payload);
      //actions.getTransactionLog(payload);
    }
    // closeModal();
  };

  const [amount, setAmount] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "transactionType") {
      if (value === "Program Refund" || value === "Pregnancy Loss Refund") {
        setAmount(programName?.refund ? programName?.refundAmonut : null);
        setValues({
          ...values,
          amount: programName?.refund ? programName?.refundAmonut : null,
          [name]: value
        });
      } else {
        setAmount(null);
        setValues({
          ...values,
          [name]: value
        });
      }
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
    actions.resetManualRefundMessage();
    closeModal();
  };

  const [refund, setRefund] = useState(
    refundDetailsConfig({
      values,
      onChange: handleInputChange,
      isAdd: false,
      onClick: closeModal,
      setValues,
      amount
    })
  );

  useEffect(() => {
    setRefund(
      refundDetailsConfig({
        values,
        onChange: handleInputChange,
        isAdd: false,
        onClick: closeModal,
        setValues,
        amount
      })
    );
  }, [values, amount]);

  return (
    <>
      <CommonModal
        open={props.openDialog}
        config={refund}
        isAdd={false}
        handleSubmit={saveRefund}
      />
      <MessageModal
        open={open}
        message={"Manual refund entry is recorded successfully"}
        closeModal={handleClose}
      />
      <WarnningMessageModal
        open={isWarningOpen}
        message={warningMessage}
        closeModal={closeWarningModal}
      />
      <LoadingModal open={loading} />
    </>
  );
};

const mapStateToProps = ({ transactionLogReducer, manualRefundReducer, programNameReducer }) => {
  return {
    transactionLog: transactionLogReducer.transactionLog,
    manualRefundList: manualRefundReducer.manualRefundList,
    loading: manualRefundReducer.loading,
    error: transactionLogReducer.error,
    success: manualRefundReducer.success,
    refundError: manualRefundReducer.error,
    programName: programNameReducer.programName
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProgramName: (payload) => {
      dispatch(actionType.getProgramName(payload));
    },
    getTransactionLog: (payload) => {
      dispatch(actionType.getTransactionLog(payload));
    },
    setManualRefundList: (payload) => {
      dispatch(actionType.setManualRefundList(payload));
    },
    resetManualRefundMessage: () => {
      dispatch(actionType.resetManualRefundMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(MedcaseRefund);
