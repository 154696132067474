//Financial Estimate questions
export const FINANCIAL_ESTIMATE_QUESTIONS_REQUESTED = "FINANCIAL_ESTIMATE_QUESTIONS_REQUESTED";
export const FINANCIAL_ESTIMATE_QUESTIONS_SUCCESS = "FINANCIAL_ESTIMATE_QUESTIONS_SUCCESS";
export const FINANCIAL_ESTIMATE_QUESTIONS_FAILED = "FINANCIAL_ESTIMATE_QUESTIONS_FAILED";
export const RESET_FINANCIAL_ESTIMATE_QUESTIONS = "RESET_FINANCIAL_ESTIMATE_QUESTIONS";

export const CREATE_FINANCIAL_ESTIMATE_REQUESTED = "CREATE_FINANCIAL_ESTIMATE_REQUESTED";
export const CREATE_FINANCIAL_ESTIMATE_SUCCESS = "CREATE_FINANCIAL_ESTIMATE_SUCCESS";
export const CREATE_FINANCIAL_ESTIMATE_FAILED = "CREATE_FINANCIAL_ESTIMATE_FAILED";

// financial estimates list
export const FINANCIAL_ESTIMATE_LIST_REQUESTED = "FINANCIAL_ESTIMATE_LIST_REQUESTED";
export const FINANCIAL_ESTIMATE_LIST_SUCCESS = "FINANCIAL_ESTIMATE_LIST_SUCCESS";
export const FINANCIAL_ESTIMATE_LIST_FAILED = "FINANCIAL_ESTIMATE_LIST_FAILED";
export const RESET_FINANCIAL_ESTIMATE_LIST = "RESET_FINANCIAL_ESTIMATE_LIST";

// financial estimates view list
export const FINANCIAL_ESTIMATE_VIEW_LIST_REQUESTED = "FINANCIAL_ESTIMATE_VIEW_LIST_REQUESTED";
export const FINANCIAL_ESTIMATE_VIEW_LIST_SUCCESS = "FINANCIAL_ESTIMATE_VIEW_LIST_SUCCESS";
export const FINANCIAL_ESTIMATE_VIEW_LIST_FAILED = "FINANCIAL_ESTIMATE_VIEW_LIST_FAILED";
export const RESET_FINANCIAL_ESTIMATE_VIEW_LIST = "RESET_FINANCIAL_ESTIMATE_VIEW_LIST";

// System COnfiguration  list
export const SYSTEM_CONFIGURATION_VIEW_LIST_REQUESTED = "SYSTEM_CONFIGURATION_VIEW_LIST_REQUESTED";
export const SYSTEM_CONFIGURATION_VIEW_LIST_SUCCESS = "SYSTEM_CONFIGURATION_VIEW_LIST_SUCCESS";
export const SYSTEM_CONFIGURATION_VIEW_LIST_FAILED = "SYSTEM_CONFIGURATION_VIEW_LIST_FAILED";
export const RESET_SYSTEM_CONFIGURATION_VIEW_LIST = "RESET_SYSTEM_CONFIGURATION_VIEW_LIST";

// financial estimates get note
export const FINANCIAL_ESTIMATE_NOTES_REQUESTED = "FINANCIAL_ESTIMATE_NOTES_REQUESTED";
export const FINANCIAL_ESTIMATE_NOTES_SUCCESS = "FINANCIAL_ESTIMATE_NOTES_SUCCESS";
export const FINANCIAL_ESTIMATE_NOTES_FAILED = "FINANCIAL_ESTIMATE_NOTES_FAILED";

// financial estimates add note
export const FINANCIAL_ESTIMATE_ADD_NOTES_REQUESTED = "FINANCIAL_ESTIMATE_ADD_NOTES_REQUESTED";
export const FINANCIAL_ESTIMATE_ADD_NOTES_SUCCESS = "FINANCIAL_ESTIMATE_ADD_NOTES_SUCCESS";
export const FINANCIAL_ESTIMATE_ADD_NOTES_FAILED = "FINANCIAL_ESTIMATE_ADD_NOTES_FAILED";

// financial estimates get cards
export const FINANCIAL_ESTIMATE_CARDS_REQUESTED = "FINANCIAL_ESTIMATE_CARDS_REQUESTED";
export const FINANCIAL_ESTIMATE_CARDS_SUCCESS = "FINANCIAL_ESTIMATE_CARDS_SUCCESS";
export const FINANCIAL_ESTIMATE_CARDS_FAILED = "FINANCIAL_ESTIMATE_CARDS_FAILED";
export const RESET_ESTIMATE_CARDS = "RESET_ESTIMATE_CARDS";

// Delete Estimate Treatment Plan
export const DELETE_ESTIMATE_TREATMENT_PLAN_REQUESTED = "DELETE_ESTIMATE_TREATMENT_PLAN_REQUESTED";
export const DELETE_ESTIMATE_TREATMENT_PLAN_SUCCESS = "DELETE_ESTIMATE_TREATMENT_PLAN_SUCCESS";
export const DELETE_ESTIMATE_TREATMENT_PLAN_FAIL = "DELETE_ESTIMATE_TREATMENT_PLAN_FAIL";

//stages
export const ESTIMATE_STAGE_LIST_REQUESTED = "ESTIMATE_STAGE_LIST_REQUESTED";
export const ESTIMATE_STAGE_LIST_SUCCESS = "ESTIMATE_STAGE_LIST_SUCCESS";
export const ESTIMATE_STAGE_LIST_FAILED = "ESTIMATE_STAGE_LIST_FAILED";

//necessary
export const NECESSARY_LIST_REQUESTED = "NECESSARY_LIST_REQUESTED";
export const NECESSARY_LIST_SUCCESS = "NECESSARY_LIST_SUCCESS";
export const NECESSARY_LIST_FAILED = "NECESSARY_LIST_FAILED";

//may be necessary
export const OPTIONAL_LIST_REQUESTED = "OPTIONAL_LIST_REQUESTED";
export const OPTIONAL_LIST_SUCCESS = "OPTIONAL_LIST_SUCCESS";
export const OPTIONAL_LIST_FAILED = "OPTIONAL_LIST_FAILED";

//add adjustments
export const ADD_ADJUSTMENTS_REQUESTED = "ADD_ADJUSTMENTS_REQUESTED";
export const ADD_ADJUSTMENTS_SUCCESS = "ADD_ADJUSTMENTS_SUCCESS";
export const ADD_ADJUSTMENTS_FAILED = "ADD_ADJUSTMENTS_FAILED";

//get adjustments
export const GET_ADJUSTMENTS_REQUESTED = "GET_ADJUSTMENTS_REQUESTED";
export const GET_ADJUSTMENTS_SUCCESS = "GET_ADJUSTMENTS_SUCCESS";
export const GET_ADJUSTMENTS_FAILED = "GET_ADJUSTMENTS_FAILED";

//update adjustments
export const UPDATE_ADJUSTMENTS_REQUESTED = "UPDATE_ADJUSTMENTS_REQUESTED";
export const UPDATE_ADJUSTMENTS_SUCCESS = "UPDATE_ADJUSTMENTS_SUCCESS";
export const UPDATE_ADJUSTMENTS_FAILED = "UPDATE_ADJUSTMENTS_FAILED";
export const RESET_UPDATE_ADJUSTMENTS = "RESET_UPDATE_ADJUSTMENTS";

export const RESET_COST_COVERAGE_MESSAGE = "RESET_COST_COVERAGE_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const RESET_NOTE_MESSAGE = "RESET_NOTE_MESSAGE";
export const RESET_MESSAGE_MODAL = "RESET_MESSAGE_MODAL";
//get adjustments
export const FERTILTY_ACCESS_PROGRAMS_REQUESTED = "FERTILTY_ACCESS_PROGRAMS_REQUESTED";
export const FERTILTY_ACCESS_PROGRAMS_SUCCESS = "FERTILTY_ACCESS_PROGRAMS_SUCCESS";
export const FERTILTY_ACCESS_PROGRAMS_FAILED = "FERTILTY_ACCESS_PROGRAMS_FAILED";
export const RESEET_FERTILTY_ACCESS_PROGRAMS = "RESEET_FERTILTY_ACCESS_PROGRAMS";
export const PROGRAM_LIST_VISUALIZATION_REQUESTED = "PROGRAM_LIST_VISUALIZATION_REQUESTED";

//refund qstns
export const REFUND_QUESTIONS_REQUESTED = "REFUND_QUESTIONS_REQUESTED";
export const REFUND_QUESTIONS_SUCCESS = "REFUND_QUESTIONS_SUCCESS";
export const REFUND_QUESTIONS_FAILED = "REFUND_QUESTIONS_FAILED";
//Personal data
export const PERSONAL_DATA_IS_REQUESTED = "PERSONAL_DATA_IS_REQUESTED";
export const PERSONAL_DATA_IS_SUCCESS = "PERSONAL_DATA_IS_SUCCESS";
export const PERSONAL_DATA_IS_FAILED = "PERSONAL_DATA_IS_FAILED";
export const RESET_PERSONAL_IS_LIST = "RESET_PERSONAL_IS_LIST";

//get partner details
export const GET_PARTNER_DETAIL_REQUESTED = "GET_PARTNER_DETAIL_REQUESTED";
export const GET_PARTNER_DETAIL_SUCCESS = "GET_PARTNER_DETAIL_SUCCESS";
export const GET_PARTNER_DETAIL_FAIL = "GET_PARTNER_DETAIL_FAIL";

//publish requested
export const PUBLISH_REQUESTED = "PUBLISH_REQUESTED";
export const PUBLISH_SUCCESS = "PUBLISH_SUCCESS";
export const PUBLISH_FAILED = "PUBLISH_FAILED";

//withdraw refund app
export const WITHDRAW_REFUND_APP_REQUESTED = "WITHDRAW_REFUND_APP_REQUESTED";
export const WITHDRAW_REFUND_APP_SUCCESS = "WITHDRAW_REFUND_APP_SUCCESS";
export const WITHDRAW_REFUND_APP_FAILED = "WITHDRAW_REFUND_APP_FAILED";

// get fapList
export const FAP_LIST_REQUESTED = "FAP_LIST_REQUESTED";
export const FAP_LIST_SUCCESS = "FAP_LIST_SUCCESS";
export const FAP_LIST_FAILED = "FAP_LIST_FAILED";
export const RESET_FAP_LIST = "RESET_FAP_LIST";
export const GET_PERSON_BY_ID = "GET_PERSON_BY_ID";
export const SET_PATIENT_DETAILS = "SET_PATIENT_DETAILS";

//get practice users
export const PRACTICE_USERS_REQUESTED = "PRACTICE_USERS_REQUESTED";
export const PRACTICE_USERS_SUCCESS = "PRACTICE_USERS_SUCCESS";
export const PRACTICE_USERS_FAILED = "PRACTICE_USERS_FAILED";

//asignee
export const ASIGNEE_APP_REQUESTED = "ASIGNEE_APP_REQUESTED";
export const ASIGNEE_APP_SUCCESS = "ASIGNEE_APP_SUCCESS";
export const ASIGNEE_APP_FAILED = "ASIGNEE_APP_FAILED";

//get treatment qstns

export const GET_TREATMENT_QUESTIONS_REQUESTED = "GET_TREATMENT_QUESTIONS_REQUESTED";
export const GET_TREATMENT_QUESTIONS_SUCCESS = "GET_TREATMENT_QUESTIONS_SUCCESS";
export const GET_TREATMENT_QUESTIONS_FAILED = "GET_TREATMENT_QUESTIONS_FAILED";
export const RESET_GET_QSTNS = "RESET_GET_QSTNS";

//reset stages,optional,necessary
export const RESET_STAGES = "RESET_STAGES";
export const RESET_OPTIONAL = "RESET_OPTIONAL";
export const RESET_NECESSARY = "RESET_NECESSARY";
export const CARD_CLICK_ACTION = "CARD_CLICK_ACTION";
export const RESET_CARD_CLICK = "RESET_CARD_CLICK";

export const CREATE_TREATMENT_PLAN_REQUESTED = "CREATE_TREATMENT_PLAN_REQUESTED";
export const CREATE_TREATMENT_PLAN_SUCCESS = "CREATE_TREATMENT_PLAN_SUCCESS";
export const CREATE_TREATMENT_PLAN_FAILED = "CREATE_TREATMENT_PLAN_FAILED";

export const INPUT_VALUES = "INPUT_VALUES";

export const RESET_REFUND_QUESTIONS = "RESET_REFUND_QUESTIONS";

//save refund application
export const SAVE_REFUND_APP_REQUESTED = "SAVE_REFUND_APP_REQUESTED";
export const SAVE_REFUND_APP_SUCCESS = "SAVE_REFUND_APP_SUCCESS";
export const SAVE_REFUND_APP_FAILED = "SAVE_REFUND_APP_FAILED";

//save draft refund application
export const SAVE_DRAFT_REFUND_APP_REQUESTED = "SAVE_DRAFT_REFUND_APP_REQUESTED";
export const SAVE_DRAFT_REFUND_APP_SUCCESS = "SAVE_DRAFT_REFUND_APP_SUCCESS";
export const SAVE_DRAFT_REFUND_APP_FAILED = "SAVE_DRAFT_REFUND_APP_FAILED";

export const FAP_ROW_DATA_REQUESTED = "FAP_ROW_DATA_REQUESTED";
export const EDIT_COST_COVERAGE = "EDIT_COST_COVERAGE";
export const RESET_WITHDRAW_RESPONSE = "RESET_WITHDRAW_RESPONSE";
export const RESET_ASSIGNEE_RESPONSE = "RESET_ASSIGNEE_RESPONSE";
export const RESET_PUBLISH_MSG = "RESET_PUBLISH_MSG";

export const RESET_SUBMIT_REFUND_APP = "RESET_SUBMIT_REFUND_APP";
export const RESET_SAVE_REFUND_APP = "RESET_SAVE_REFUND_APP";

//get refund application response
export const GET_REFUND_RESPONSE_REQUESTED = "GET_REFUND_RESPONSE_REQUESTED";
export const GET_REFUND_RESPONSE_SUCCESS = "GET_REFUND_RESPONSE_SUCCESS";
export const GET_REFUND_RESPONSE_FAILED = "GET_REFUND_RESPONSE_FAILED";
export const RESET_REFUND_RESPONSE = "RESET_REFUND_RESPONSE";

//save edited outcome form
export const UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_REQUESTED =
  "UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_REQUESTED";
export const UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_SUCCESS =
  "UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_SUCCESS";
export const UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_FAILED =
  "UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_FAILED";

export const EDITED_AUTH_DATA_SAVE_REQUESTED = "EDITED_AUTH_DATA_SAVE_REQUESTED";
export const EDITED_AUTH_DETAILS_SAVE_SUCCESS = "EDITED_AUTH_DETAILS_SAVE_SUCCESS";
export const EDITED_AUTH_DETAILS_SAVE_FAILED = "EDITED_AUTH_DETAILS_SAVE_FAILED";

//Clinical Data requested
export const CLINICAL_TREATMENT_DATA_REQUESTED = "CLINICAL_TREATMENT_DATA_REQUESTED";
export const CLINICAL_TREATMENT_DATA_SUCCESS = "CLINICAL_TREATMENT_DATA_SUCCESS";
export const CLINICAL_TREATMENT_DATA_FAILED = "CLINICAL_TREATMENT_DATA_FAILED";
// get Edit program
export const EDIT_PROGRAM_FAP_VISIBILITY_REQUESTED = "EDIT_PROGRAM_FAP_VISIBILITY_REQUESTED";
export const EDIT_PROGRAM_FAP_VISIBILITY_SUCCESS = "EDIT_PROGRAM_FAP_VISIBILITY_SUCCESS";
export const EDIT_PROGRAM_FAP_VISIBILITY_FAILED = "EDIT_PROGRAM_FAP_VISIBILITY_FAILED";

//reset fap visibility data
export const RESET_FAP_VISIBILITY = "RESET_FAP_VISIBILITY";

// put edit program
export const ADD_FAP_VISIBILITY_REQUESTED = "ADD_FAP_VISIBILITY_REQUESTED";
export const ADD_FAP_VISIBILITY_SUCCESS = "ADD_FAP_VISIBILITY_SUCCESS";
export const ADD_FAP_VISIBILITY_FAILED = "ADD_FAP_VISIBILITY_FAILED";

//edit treatment plan
export const EDIT_TREATMENT_PLAN_REQUESTED = "EDIT_TREATMENT_PLAN_REQUESTED";
export const EDIT_TREATMENT_PLAN_SUCCESS = "EDIT_TREATMENT_PLAN_SUCCESS";
export const EDIT_TREATMENT_PLAN_FAILED = "EDIT_TREATMENT_PLAN_FAILED";
export const EDIT_TREATMENT = "EDIT_TREATMENT";
export const RESET_EDIT_TREATMENT = "RESET_EDIT_TREATMENT";

// get treatment plans
export const GET_TREATMENT_PLAN_LIST_REQUESTED = "GET_TREATMENT_PLAN_LIST_REQUESTED";
export const GET_TREATMENT_PLAN_LIST_SUCCESS = "GET_TREATMENT_PLAN_LIST_SUCCESS";
export const GET_TREATMENT_PLAN_LIST_FAILED = "GET_TREATMENT_PLAN_LIST_FAILED";

// get medcase patient Info
export const GET_MEDCASE_PATIENT_INFO_REQUESTED = "GET_MEDCASE_PATIENT_INFO_REQUESTED";
export const GET_MEDCASE_PATIENT_INFO_SUCCESS = "GET_MEDCASE_PATIENT_INFO_SUCCESS";
export const GET_MEDCASE_PATIENT_INFO_FAILED = "GET_MEDCASE_PATIENT_INFO_FAILED";
export const SET_ESTIMATE_ID = "SET_ESTIMATE_ID";

// approve refund application
export const APPROVE_DENY_REFUND_APPLICATION_REQUESTED =
  "APPROVE_DENY_REFUND_APPLICATION_REQUESTED";
export const APPROVE_DENY_REFUND_APPLICATION_SUCCESS = "APPROVE_DENY_REFUND_APPLICATION_SUCCESS";
export const APPROVE_DENY_REFUND_APPLICATION_FAILED = "APPROVE_DENY_REFUND_APPLICATION_FAILED";

//reset partner data
export const RESET_PARTNER_DATA = "RESET_PARTNER_DATA";

//Estimate list for PDF
export const ESTIMATED_LIST_PDF_REQUESTED = "ESTIMATED_LIST_PDF_REQUESTED";
export const ESTIMATED_LIST_PDF_SUCCESS = "ESTIMATED_LIST_PDF_SUCCESS";
export const ESTIMATED_LIST_PDF_FAILED = "ESTIMATED_LIST_PDF_FAILED";
export const RESET_ESTIMATED_LIST_PDF = "RESET_ESTIMATED_LIST_PDF";

export const RESET_ADD_ESTIMATES = "RESET_ADD_ESTIMATES";
export const RESET_ADD_TREATMENT = "RESET_ADD_TREATMENT";
