import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Select,
  Typography,
  Button,
  Autocomplete
} from "@mui/material";
import QuickAdd from "../../shared/components/QuickAdd";
import { connect } from "react-redux"; //useDispatch
import { useEffect, useState } from "react";
import { getPractices } from "../../admin/actions";
import * as actionType from "../actions";
import * as actionTypeEstimate from "../../workspace/actions";
// import { refundApplicationConstants } from "./../../shared/constants/constants";
// import moment from "moment/moment";
// import { FapRowData } from "../../workspace/actions";
// import { resetEstimateCards } from "../../workspace/actions";
import { v4 as uuidv4 } from "uuid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Link } from "react-router-dom";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const TrakFinancialEstimates = (props) => {
  const [yesterday] = useState(moment().subtract(1, "days").format("YYYY-MM-DD"));

  let inputValue = {
    md: "",
    cycleType: "",
    clinicalLocation: ""
  };

  const { actions, trakFinancialEstimateList = [], loading, practices = [], metaData = {} } = props;
  //   const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState({
    patientNameOrId: "",
    practiceId: "",
    createDateFrom: null,
    createDateTo: null,
    publishDateFrom: yesterday,
    publishDateTo: yesterday,
    pgtaMedcaseStatus: "",
    refundEligibility: "",
    medcaseStatus: "",
    contractStatus: ""
  });
  const [csvData, setCsvData] = useState([]);
  const [valuesForPatient] = useState(inputValue);

  function formateDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${month}/${day}/${year}`;
  }

  // function CustomToolbar() {
  //   return (
  //     <GridToolbarExport
  //       sx={{ textTransform: "none", width: "140px" }}
  //       printOptions={{
  //         hideFooter: true,
  //         hideToolbar: true,
  //         allColumnsOptional: true,
  //         allColumns: true,
  //         pageSize: pageSize,
  //         pageStyle: `
  //     @page {
  //       size: A4 landscape;
  //       margin: 0;
  //     }`
  //       }}
  //     />
  //   );
  // }

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      patientNameAndKey:
        row?.patientName && row.patientKey
          ? `${row?.patientName ? row.patientName : ""} & ${row?.patientKey ? row.patientKey : ""}`
          : "",
      partnerNameAndKey:
        row?.partnerName && row.partnerKey
          ? `${row?.partnerName ? row.partnerName : ""} & ${row?.partnerKey ? row.partnerKey : ""}`
          : "",
      patientName: row?.patientName ? row.patientName : "",
      patientKey: row?.patientKey ? row.patientKey : "",
      patientId: row?.patientId ? row.patientId : "",
      patientDOB: row?.patientDOB ? row.patientDOB : "",
      patientAge: row?.patientAge ? row.patientAge : "",
      patientEmail: row?.patientEmail ? row.patientEmail : "",
      cellPhone: row?.cellPhone ? row.cellPhone : "",
      homePhone: row?.homePhone ? row.homePhone : "",
      practice: row?.practice ? row.practice : "",
      practice_id: row?.practice_id ? row.practice_id : "",
      primaryMD: row?.primaryMD ? row.primaryMD : "",
      partnerName: row?.partnerName ? row.partnerName : "",
      partnerId: row?.partnerId ? row.partnerId : "",
      partnerEmail: row?.partnerEmail ? row.partnerEmail : "",
      partnerKey: row?.partnerKey ? row.partnerKey : "",
      treatmentPlan: row?.treatmentPlan ? row.treatmentPlan : "",
      estimateTpId: row?.estimateTpId ? row.estimateTpId : "",
      estimateCreatedDate: row?.estimateCreatedDate ? formateDate(row.estimateCreatedDate) : "",
      daySinceCreatedDate: row?.daySinceCreatedDate ? Number(row.daySinceCreatedDate) : "",
      estimatePublishedDate: row?.estimatePublishedDate
        ? formateDate(row.estimatePublishedDate)
        : "",
      estimatePublishedBy: row?.estimatePublishedBy ? row.estimatePublishedBy : "",
      refundEligibility: row?.refundEligibility ? row.refundEligibility : "",
      refundApplicationStatus: row?.refundApplicationStatus ? row.refundApplicationStatus : "",
      refundApplicationNotes: row?.refundApplicationNotes ? row.refundApplicationNotes : "",
      contractStatus: row?.contractStatus ? row.contractStatus : "",
      paymentStatus: row?.paymentStatus ? row.paymentStatus : "",
      medcase:
        row?.medcaseId && row?.medcaseStatus && row?.medcaseStatusDate
          ? `${row.medcaseId} & ${row.medcaseStatus} & ${formateDate(row.medcaseStatusDate)}`
          : "",
      medcaseId: row?.medcaseId ? row.medcaseId : "",
      medcaseStatus: row?.medcaseStatus ? row.medcaseStatus : "",
      medcaseStatusDate: row?.medcaseStatusDate ? row.medcaseStatusDate : "",
      daySinceMedcaseStatus: row?.daySinceMedcaseStatus ? Number(row.daySinceMedcaseStatus) : "",
      programName: row?.programName ? row.programName : "",
      daySincePublishedDate: row?.daySincePublishedDate ? Number(row.daySincePublishedDate) : "",
      practiceName: row?.practiceName ? row.practiceName : "",
      estimateId: row?.estimateId ? row.estimateId : ""
    }));
  };

  const columns = [
    {
      field: "patientNameAndKey",
      headerName: "PATIENT NAME & ID",
      width: 60,
      renderCell: ({ row }) => {
        console.log("row: ", row);
        const patientName = row.patientName || "";
        const nameParts = patientName.split(" ");
        const firstName = nameParts[0] || "";
        const lastName = nameParts.slice(1).join(" ");

        return (
          <Link to={`/patients/${row?.patientId}/personal`}>
            <Button
              color="primary"
              variant="text"
              className="word-wrap"
              onClick={() => {
                actions.getPersonalList({ practiceId: row?.practice_id, valuesForPatient });
                localStorage.setItem("practiceId", row?.practice_id);
              }}>
              <div style={{ flexFlow: "row" }}>
                <Typography style={{ fontSize: "12px" }}>{`${firstName} ${lastName}`}</Typography>
                <Typography style={{ fontSize: "12px" }}>{`${row?.patientKey}`}</Typography>
              </div>
            </Button>
          </Link>
        );
      }
    },
    {
      field: "estimateId",
      headerName: "Estimate ID",
      width: 60,
      editable: false,
      renderCell: ({ row }) => {
        let estimateNumber = row.estimateId.split("-");
        console.log("estimateNumber:", estimateNumber);
        return (
          <Link
            variant="contained"
            target="_blank"
            to={`/workspace/financial-estimates/${row.patientId}?PT=PD`}>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                localStorage.setItem("patientName", row.patientName);
                actions.resetEstimateViewList();
              }}
              className="word-wrap">
              {estimateNumber[1] ? estimateNumber[1] : "--"}
            </Button>
          </Link>
        );
      }
    },
    {
      field: "patientDOB",
      headerName: "Patient DOB & Age",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <div style={{ flexFlow: "row" }}>
            <Typography>{row?.patientDOB ? formateDate(row?.patientDOB) : "--"}</Typography>
            <Typography>{row?.patientAge ? row?.patientAge + " years" : "--"}</Typography>
          </div>
        );
      }
    },
    {
      field: "patientEmail",
      headerName: "Patient Email",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.patientEmail ? row?.patientEmail : "--"}</Typography>;
      }
    },
    {
      field: "cellPhone",
      headerName: "Cell Phone",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.cellPhone ? row?.cellPhone : "--"}</Typography>;
      }
    },
    {
      field: "homePhone",
      headerName: "Home Phone",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.homePhone ? row?.homePhone : "--"}</Typography>;
      }
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {row?.practiceName ? row?.practiceName : "--"}
          </Typography>
        );
      }
    },
    {
      field: "primaryMD",
      headerName: "Primary MD",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.primaryMD ? row?.primaryMD : "--"}</Typography>;
      }
    },
    {
      field: "partnerNameAndKey",
      headerName: "Partner Name & ID",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <div style={{ flexFlow: "row" }}>
            <Typography>{row?.partnerName ? row?.partnerName : "--"}</Typography>
            <Typography>{row?.partnerKey ? row?.partnerKey : "--"}</Typography>
          </div>
        );
      }
    },
    {
      field: "partnerEmail",
      headerName: "Partner Email",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.partnerEmail ? row?.partnerEmail : "--"}</Typography>;
      }
    },
    {
      field: "treatmentPlan",
      headerName: "Treatment Plan",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.treatmentPlan ? row?.treatmentPlan : "--"}</Typography>;
      }
    },
    {
      field: "estimateCreatedDate",
      headerName: "Estimate Created Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.estimateCreatedDate
              ? formateDate(moment(new Date(row?.estimateCreatedDate)).format("L"))
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "daySinceCreatedDate",
      headerName: "Days Since Created Date",
      width: 60,
      renderCell: ({ row }) => {
        // const createdDate = new Date(row.practiceId);
        // const currentDate = new Date();
        // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
        // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return (
          <Typography>
            {row?.daySinceCreatedDate ? row?.daySinceCreatedDate : "-"}
            {row?.daySinceCreatedDate ? " days" : "-"}
          </Typography>
        );
      }
    },
    {
      field: "estimatePublishedDate",
      headerName: "Estimate Publish Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.estimatePublishedDate
              ? formateDate(moment(new Date(row?.estimatePublishedDate)).format("L"))
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "daySincePublishedDate",
      headerName: "Days Since Published Date",
      width: 60,
      renderCell: ({ row }) => {
        // const createdDate = new Date(row.practiceId);
        // const currentDate = new Date();
        // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
        // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return (
          <Typography>
            {row?.daySincePublishedDate ? row?.daySincePublishedDate : "-"}
            {row?.daySincePublishedDate ? " days" : "-"}
          </Typography>
        );
      }
    },
    {
      field: "estimatePublishedBy",
      headerName: "Estimate Publish By",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>{row?.estimatePublishedBy ? row?.estimatePublishedBy : "--"}</Typography>
        );
      }
    },
    {
      field: "refundEligibility",
      headerName: "Refund Eligibility",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.refundEligibility ? row?.refundEligibility : "--"}</Typography>;
      }
    },
    {
      field: "refundApplicationStatus",
      headerName: "Refund Application Status",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.refundApplicationStatus ? row?.refundApplicationStatus : "--"}
          </Typography>
        );
      }
    },
    {
      field: "refundApplicationNotes",
      headerName: "Refund Application Notes",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.refundApplicationNotes ? row?.refundApplicationNotes : "--"}
          </Typography>
        );
      }
    },
    {
      field: "contractStatus",
      headerName: "Contract Status",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.contractStatus ? row?.contractStatus : "--"}</Typography>;
      }
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.paymentStatus ? row?.paymentStatus : "--"}</Typography>;
      }
    },
    {
      field: "medcase",
      headerName: "Medcase ID Status & Status Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <div style={{ flexFlow: "row" }}>
            <Link
              style={{
                textDecoration: "none",
                color: "#1BA3AD",
                fontFamily: "Rubik"
              }}
              to={{
                pathname: `/trak/caselookup/caselist/${row.medcaseId}/status`
                // state: { data: row.medcaseId }
              }}
              target="_blank">
              {row?.medcaseId}
            </Link>
            <Typography>{row?.medcaseStatus ? row?.medcaseStatus : "--"}</Typography>
            <Typography>
              {row?.medcaseStatusDate ? formateDate(row?.medcaseStatusDate) : "--"}
            </Typography>
          </div>
        );
      }
    },
    {
      field: "daySinceMedcaseStatus",
      headerName: "Days Since Status Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.daySinceMedcaseStatus ? row?.daySinceMedcaseStatus + " days" : "--"}
          </Typography>
        );
      }
    },
    {
      field: "programName",
      headerName: "Program Name",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.programName ? row?.programName : "--"}</Typography>;
      }
    }
  ];

  // const calculateDays = (fromDate, toDate) => {
  //   var date1 = moment(fromDate);
  //   var date2 = moment("2022-12-30");
  //   var days = date1.diff(date2, "days");
  // };

  useEffect(() => {
    if (trakFinancialEstimateList.length === 0) {
      getList();
    }
  }, []);

  useEffect(() => {
    if (practices.length === 0) {
      actions.getPractices();
    }
    if (Object.keys(metaData).length === 0) {
      actions.getMetaData();
    }
  }, []);

  useEffect(() => {
    if (trakFinancialEstimateList.length != 0) {
      let rows = trakFinancialEstimateList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
      const csvDataMode = prepareCsvData(rows);
      console.log("csvDataMode: ", csvDataMode);
      setCsvData(csvDataMode);
    } else {
      setCsvData([]);
      setRows([]);
    }
  }, [loading]);

  const getList = () => {
    actions.getTrakFinacialEstimateList({ values });
  };
  const handleInputChange = (e) => {
    actions.resetTrakFinancialEstimateList();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleAutoCompleteChange = (e, name, value) => {
    // const { name, value } = e.target;
    // actions.resetTrakFinancialEstimateList();
    if (value) {
      setValues({
        ...values,
        [name]: value.value
      });
    } else {
      setValues({
        ...values,
        [name]: null
      });
    }
  };

  const handleSearchClick = (e, searchText) => {
    // actions.resetTrakFinancialEstimateList();
    values.patientNameOrId = searchText;
    setValues({
      ...values
    });
    getList();

    console.log(values);
  };

  const clearSearchClick = () => {
    values.patientNameOrId = "";
    setValues({ ...values });
  };

  const pgtaMedcasetstaus = [
    "Clinical Review",
    "Complete",
    "Enrolled",
    "Fertility Access Withdrew",
    "Medically Cleared",
    "Not Enrolled",
    "Patient Withdrew",
    "Program Complete - Contract Expired",
    "Re-Enrolled"
  ];

  return (
    <Box className="refund-application">
      <Box className="teal-bg-color pad-5">
        {/* Find a pateint */}
        <QuickAdd
          text={1}
          search={7}
          handleSearchClick={handleSearchClick}
          clearSearchClick={clearSearchClick}
        />

        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            {/* Practice */}
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Practice</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                name="practiceId"
                fullWidth
                value={values.practiceId}
                onChange={handleInputChange}>
                {practices &&
                  practices.map((item, index) => {
                    return (
                      <MenuItem value={item.practiceId} key={index}>
                        {item.name} - {item.practiceId}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={values?.practiceId}
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.practiceId}`,
                    name: item.name,
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "practiceId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.25}></Grid>
            {/* PGTA Medcase status */}
            <Grid item xs={1}>
              <Typography>PGTA Medcase Status</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="pgtaMedcaseStatus"
                value={values.pgtaMedcaseStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {pgtaMedcasetstaus.length > 0 &&
                  pgtaMedcasetstaus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="pgtaMedcaseStatus"
                value={values.pgtaMedcaseStatus}
                options={pgtaMedcasetstaus.map((item, index) => {
                  return {
                    id: index,
                    label: item,
                    value: item,
                    name: "pgtaMedcaseStatus"
                  };
                })}
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "pgtaMedcaseStatus", value);
                }}
                renderInput={(params) => <TextField {...params} label="PGTA Medcase Status..." />}
                className="flex-4"
              />
            </Grid>
          </Grid>
        </Grid>

        {/*Date picker */}
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            {" "}
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Create Date From </Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.createDateFrom}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, createDateFrom: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Create Date To</Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.createDateTo}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, createDateTo: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        {/*Date picker */}
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Published Date From </Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.publishDateFrom}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, publishDateFrom: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Published Date To</Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.publishDateTo}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, publishDateTo: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        {/* REfund Eligibility & Medcase Status */}
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography>Refund Eligibility</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="refundEligibility"
                value={values.refundEligibility}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="refundEligibility"
                value={values.refundEligibility}
                options={[
                  {
                    id: 1,
                    label: "Select",
                    value: "",
                    name: "refundEligibility"
                  },
                  {
                    id: 2,
                    label: "Yes",
                    value: "Y",
                    name: "refundEligibility"
                  },
                  {
                    id: 3,
                    label: "No",
                    value: "N",
                    name: "refundEligibility"
                  }
                ]}
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "refundEligibility", value);
                }}
                renderInput={(params) => <TextField {...params} label="Refund Eligibility..." />}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Medcase Status </Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="medcaseStatus"
                value={values.medcaseStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="medcaseStatus"
                value={values.medcaseStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      value: item,
                      name: "medcaseStatus"
                    };
                  })
                }
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "medcaseStatus", value);
                }}
                renderInput={(params) => <TextField {...params} label="Medcase Status..." />}
                className="flex-4"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Contract Status</Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                name="contractStatus"
                value={values.contractStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {Object.keys(metaData).length > 0 &&
                  metaData?.contractStatus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          loading={loading}
          rows={csvData}
          rowCount={rows.length}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={pageSize}
          onPaginationModelChange={setPageSize}
          pageSize={pageSize.pageSize}
          className="mt-8"
          getRowId={() => uuidv4()}
          localeText={"Export Button"}
          isExport={true}
          pagination
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ practices, trakFinancialEstimatesReducer, caseUpdateReducer }) => {
  return {
    practices: practices.practices,
    trakFinancialEstimateList: trakFinancialEstimatesReducer.trakFinancialEstimateList,
    loading: trakFinancialEstimatesReducer.loading,
    error: trakFinancialEstimatesReducer.error,
    metaData: caseUpdateReducer.metaData
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(getPractices());
    },
    getTrakFinacialEstimateList: (payload) => {
      dispatch(actionType.getTrakFinacialEstimateList(payload));
    },
    resetTrakFinancialEstimateList: () => {
      dispatch(actionType.resetTrakFinancialEstimateList());
    },
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    resetEstimateViewList: () => {
      dispatch(actionTypeEstimate.resetEstimateViewList());
    },
    getPersonalList: (payload) => {
      dispatch(actionType.getPersonalList(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TrakFinancialEstimates);
