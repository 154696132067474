import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const token =
  "eyJraWQiOiJITUhkS2FoQSt0NnU0S3BtZjVNaFwvWGg4R1BUKyt4YllFRlhPNmY0N0pBST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI5N2FjODM5NS1lNTllLTRlODAtODRkMC00YTU5MGVhMmRkYmEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl9ZUExzVWkweFUiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiI3bG80YzVsbmk5czVkdjlocW1iZDlyc2dlaiIsImV2ZW50X2lkIjoiOWY3MTg2ZjQtMTFkYS00MWExLTg3MjEtMTExM2Y1MmMzNjA3IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBwaG9uZSBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTY1NDY3OTA5NywiZXhwIjoxNjU0NjgyNjk3LCJpYXQiOjE2NTQ2NzkwOTgsImp0aSI6Ijc4NDlmY2MzLTI5M2YtNDI5ZC1hOTIzLTExZWFjMGZhN2M2MSIsInVzZXJuYW1lIjoibmlraGlsc3A4NTUifQ.enY9E7o5AT0ueUIebFsZVKgUbfm9K0jZ_2-QehrkAqO6ihv1xk020xXmKZQTQdAT74iPLqEgve8JO_LEqyos473UE7TR2_mZZeV4Jp8QMD9L-t_OsAWsLyLqF9aj3mE3ncwcYi8U4B2VchCz-2KnOmWUp-fLAKBRuVDXjQUDuOijjVCNACW32zmYIndvKAFO4Bh1VFsOyBXLMedTV5TtMALG85rJsqi9IbDf_DgDkWt5iYld3EIwcWF4XPKm-GhrkMxMqCaAvON5Kz0szaWC5lQFUp2Q-InMCkefiWJMIWoNIxp-xMCPm223GGBtHHrNg1eIX4Z2H7OBYczGJiQqHw";

export const loanDisclaimer = `Qualified applicants may be approved for a 24 to 84 month
term with varying APR ranges based upon credit profile. Eligibility for a loan is not
guaranteed. All loan applications are subject to credit review and approval and offered
loan terms depend upon the applicant's credit score, requested amount, requested loan
term, and credit usage and history. Not all borrowers receive the lowest interest rate.
Rates and terms are subject to change at any time without prior notice.`;
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeString = (string) => {
  return string.toUpperCase();
};

export const isEmailValid = (email) => {
  //eslint-disable-next-line
  let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let res = { status: false, msg: "Invalid Email!" };
  if (emailRegEx.test(email)) {
    res.status = true;
    res.msg = "";
  }
  return res;
};
export const isPhoneNumberValid = (phone) => {
  let phoneRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  let res = { status: false, msg: "Invalid phone number." };
  if (phoneRegEx.test(phone)) {
    res.status = true;
    res.msg = "";
  }
  return res;
};

export const calculateAge = (birthDate) => {
  let dob = new Date(birthDate);
  let month_diff = Date.now() - dob.getTime();
  let age_dt = new Date(month_diff);
  let year = age_dt.getUTCFullYear();
  let age = Math.abs(year - 1970);
  return age;
};
export const MSG_TXT1 = `If you need immediate assistance, call  &lt;b&gt;954-247-6200&lt;/b&gt;  or if this is a medical emergency, please call 911.`;
export const MSG_TXT2 =
  "Messaging should be used for rotuine matters only and not for urgent concerns, Messages are monitored and will be responded to by 2 business days.";
export const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
export const enumUsStates = [
  { value: "AK", name: "Alaska" },
  { value: "TX", name: "Texas" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" }
];

export const MSG = {
  SAVED_SUCCESSFULLY: "Saved Successfully",
  UPDATED_SUCCESSFULLY: "Updated Successfully",
  DELETED_SUCCESSFULLY: "Deleted Successfully",
  APPROVED_SUCCESSFULLY: "Application Approved",
  DENIED_SUCCESSFULLY: "Application Denied",
  NOTE_SAVED: "Note Saved Successfully",
  STATUS_SAVED: "Status Updated Successfully"
};
export const ACKNOWLEDGE_TEXT =
  "***This Information being sumbitted for the above patient is accurate and complete to the best of my knowlegde.***";
export const externalIdoptions = ["NextGen", "Fert"];
export const prsWarning =
  "You will not able to edit the data entered above once saved, do you still want to save this data";

export const pgtaOptions = ["Voluntary", "Imposed", "Optout", "NA"];

export const DATE_RANGE = ["15", "30", "45", "60", "90", "120", "150", "180"];

export const ESTIMATE_STATUS = ["In-Progress", "Published", "Expired", "Withdraw"];
export const ESTIMATE_TYPE = [
  { label: "Not Estimated", value: `No Estimates` },
  { label: "Detailed Estimate", value: "D" },
  { label: "Preliminary Estimate", value: "P" }
];
export const REFUND_APPLICATION_STATUS = [
  "Incomplete",
  "All",
  "NA",
  // "Withdrawn",
  // "Submitted Approved",
  // "Submitted Denied",
  //"Submitted Need Clinical Review",
  "Clinical Review",
  "Contingency Approval",
  "Medically Cleared",
  "Application Withdrawn",
  "Rejected Incomplete",
  "Rejected Not Medically Appropriate"
];
export const MEDCASE_STATUS = [
  "All",
  "Application Withdrawn",
  "Clinical Review",
  "Contingency Approval",
  "Enrolled",
  "Incomplete",
  "Inquiry",
  "Fertility Access Withdrew",
  "Medically Cleared",
  "Not Enrolled",
  "Online Application",
  "Patient Withdraw",
  "Program Complete Successful",
  "Program Complete Unsuccessful",
  "Re-Enrolled",
  "Rejected Incomplete",
  "Rejected not medically appropriate"
];
export const CURRENT_AUTHORIZATION_CYCLE = [
  "All",
  "IVF",
  "IVF Expired",
  "FET",
  "FET Expired",
  "Submitted",
  "Requested",
  "Completed"
];
export const getQueryString = (obj) => {
  let str = "";
  for (var key in obj) {
    if (obj[key] != "" && obj[key] != "default") {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + obj[key];
    }
  }
  return "?" + str;
};

export const GLOBAL_DATA_FORM = {
  category: "G",
  type: "",
  item: "",
  amountType: "$",
  amount: "",
  comment: ""
};

export const LINE_ITEM_FORM = {
  category: "LI",
  type: "",
  item: "",
  amountType: "$",
  amount: "",
  comment: ""
};

export const SUPPLEMENT_FORM = {
  category: "S",
  type: "",
  item: "",
  amountType: "$",
  amount: "",
  comment: ""
};

export const fapCardsStore = {
  programVisualization: [
    {
      boxNumber: "PC-9",
      boxName: "Refund Program",
      createdBy: null,
      programs: [
        {
          programId: "PI-27",
          programName: "1 cycle refund",
          noOfCycle: 2,
          refund: false,
          effectiveDate: null,
          programPrice: 9000
        },
        {
          programId: "PI-28",
          programName: "2 cycle refund",
          noOfCycle: 2,
          refund: false,
          effectiveDate: null,
          programPrice: 9455
        },
        {
          programId: "PI-28",
          programName: "2 cycle refund",
          noOfCycle: 2,
          refund: false,
          effectiveDate: null,
          programPrice: 11100
        }
      ]
    },
    {
      boxNumber: "PC-5",
      boxName: "Non-Refund Program",
      createdBy: null,
      programs: [
        {
          programId: "PI-12",
          programName: "1 cycle  refund",
          noOfCycle: 1,
          refund: false,
          effectiveDate: null,
          programPrice: 6167
        },
        {
          programId: "PI-12",
          programName: "2 cycle  refund",
          noOfCycle: 1,
          refund: false,
          effectiveDate: null,
          programPrice: 7500
        }
      ]
    },

    {
      boxNumber: null,
      boxName: "Single cycle",
      createdBy: null,
      programs: [
        {
          programId: null,
          programName: "1 cycle",
          noOfCycle: 1,
          refund: false,
          effectiveDate: null,
          programPrice: 18100
        },
        {
          programId: null,
          programName: "2 cycle",
          noOfCycle: 3,
          refund: false,
          effectiveDate: null,
          programPrice: 36100
        },
        {
          programId: null,
          programName: "3 cycle",
          noOfCycle: 3,
          refund: false,
          effectiveDate: null,
          programPrice: 54100
        }
      ]
    }
  ],
  globalPriceOfFet: 1600,
  discountedGlobalTpPrice: 4000
};

export const withdrawOptions = [
  "Pay Per Cycle Selected",
  "No Refund MCP Selected",
  "Insurance Coverage",
  "Does not Meet Criteria",
  "Patient Opted out"
];

export const approvalStatus = [
  { key: "Medically Cleared", value: "Submitted Approved" },
  { key: "Contingency Approval", value: "Contingency Approval" }
];

export const declinedStatus = [
  { key: "Rejected Incomplete", value: "Rejected Incomplete" },
  {
    key: "Rejected Not Medically Appropriate",
    value:
      "This patient is not eligible for Refund at this time.Please reference clinical criteria for reasons."
  }
];

export const fapViewProgram = {
  "Refund Program": {
    programSummary: "Bring home a baby or receive a refund",
    discountStr: "Significantly discounted bundled pricing",
    programLabel: "Unlimited Frozen Transfers"
  },
  "Non-Refund Program": {
    programSummary: "Embryo banking allowed",
    discountStr: "Discounted bundled pricing",
    programLabel: "Unlimited Frozen Transfers"
  },
  "Single cycle": {
    programSummary: "Pay for treatments as needed",
    discountStr: "Discounted bundled pricing unavailable",
    //programLabel: "1 Frozen Transfer Per Cycle"
    programLabel: ""
  },

  "Pay Per Cycle": {
    programSummary: "Pay for treatments as needed",
    discountStr: "Discounted bundled pricing unavailable",
    //programLabel: "1 Frozen Transfer Per Cycle"
    programLabel: ""
  }
};
export const APPLY_LOAN =
  "https://secure1.capexmd.com:4443/LoanApplicationSystem/usf-prequalification-request.php";

export const programBoxName = {
  SINGLE_CYCLE: "Single cycle",
  REFUND_PROGRAM: "Refund Program",
  NON_REFUND_PROGRAM: "Non-Refund Program",
  PAY_PER_CYCLE: "Pay Per Cycle"
};
export const PROGRAM_NAME_ARR = ["Refund Program", "Non-Refund Program"];
const boxContainerData = [
  { programCount: 4, minHeight: 230 },
  { programCount: 5, minHeight: 280 },
  { programCount: 6, minHeight: 330 }
];
export const getBarContainerHeight = (programLength) => {
  let height;
  boxContainerData.map((item) => {
    if (item.programCount == programLength) {
      height = item.minHeight;
    }
  });
  return height;
};

export const publishToPatientConstants = {
  REFUND_MISSING: "Refund Application Missing",
  PRICE_CHANGE:
    "Price Change Alert! Prices have changed in the database after you have created this estimate. Please review the new price before publishing this estimate"
};

export const editProgramConstants = {
  question: "Do you plan to discuss the refund options for this treatment plan with the patient?*",
  reasonLabel: "Reason for not discussing refund options for this treatment*",
  noteForNo:
    "Selecting this option will disable the ability to submit a refund application for this treatment plan. You can always change your selection later or submit a refund application for another plan.",
  radioOptions: [
    { label: "Yes, Show all Fertility Access Programs", value: "Yes" },
    { label: "No, Hide Fertility Access Programs", value: "No" }
  ],
  reasonOptions: ["Do not meet clinical requirements", "Patient opted out", "Other"]
};

export const OUTCOME_OPTION_DATA = {
  Pregnant: ["Single", "Twins", "Triplet", "Other"],
  "Not Pregnant": ["Miscarriage", "Biochemical", "Ectopic", "Freeze All"],
  "Cycle Cancelled": [
    "Patient paid for monitoring or IUI",
    "Fertility Access paying for monitoring or IUI",
    "Services were performed",
    "No services were performed"
  ]
};

export const OUTCOME_DATA = ["Pregnant", "Not Pregnant", "Cycle Cancelled"];
export const ADITIONAL_SERVICES = [
  { name: "pgt", label: "PGT" },
  { name: "icsi", label: "ICSI" }
];
export const REQUESTED_SERVICES = [
  { value: "Retrieval", label: "Retrieval" },
  { value: "FET", label: "FET" }
];
export const refundApplicationConstants = {
  status: {
    name: "Refund Application Status",
    values: [
      "All",
      "Incomplete",
      "Clinical Review",
      "Contingency Approval",
      "Medically Cleared",
      "Application Withdrawn",
      "Rejected Incomplete",
      "Rejected Not Medically Appropriate"
    ]
  },
  daysSinceCreation: {
    name: "Results within last",
    values: ["10", "30", "90", "180", "All"]
  }
};

export const medCasePatientForm = {
  personKey: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  defaultPhone: "",
  phoneNumber: "",
  email: "",
  defaultAddress: "mailing",
  addressLine1: "",
  city: "",
  addressLine2: "",
  state: "",
  zip: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  mailingAddrLine1: "",
  mailingAddrLine2: "",
  billingAddrLine1: "",
  billingAddrLine2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  cellPhone: "",
  homePhone: "",
  workPhone: ""
};
export const medCasePartnerForm = {
  firstName: "",
  lastName: "",
  birthDate: "",
  defaultPhone: "",
  phoneNumber: "",
  email: "",
  defaultAddress: "mailing",
  addressLine1: "",
  city: "",
  addressLine2: "",
  state: "",
  zip: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  mailingAddrLine1: "",
  mailingAddrLine2: "",
  billingAddrLine1: "",
  billingAddrLine2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  cellPhone: "",
  homePhone: "",
  workPhone: ""
};

export const formattedDate = (str) => {
  let a = str;
  const year = a.slice(0, 4);
  const month = a.slice(4, 6);
  const day = a.slice(6, 8);
  return year + "/" + month + "/" + day;
};

export const convertStringToDate = (formattedDate) => {
  return new Date(formattedDate).toLocaleDateString();
};

export const handleValidityBtn = (
  values,
  partnerValues,
  isActive,
  practiceId,
  isManual,
  patientActive
) => {
  let isValid =
    values.firstName !== "" &&
    values.lastName !== "" &&
    values.birthDate !== "" &&
    values.email !== "" &&
    values.addressLine1 !== "" &&
    values.city !== "" &&
    values.state !== "" &&
    values.zip !== "";
  if (isActive) {
    let isValidPatient =
      partnerValues.firstName !== "" &&
      partnerValues.lastName !== "" &&
      partnerValues.birthDate !== "" &&
      partnerValues.email !== "" &&
      partnerValues.addressLine1 !== "" &&
      partnerValues.city !== "" &&
      partnerValues.state !== "" &&
      partnerValues.zip !== "";

    return isValid && isValidPatient && practiceId != "" && !isManual
      ? !patientActive.activePartner?.active
        ? true
        : false
      : isValid && isValidPatient && practiceId
      ? true
      : false;
  }
  return isValid && practiceId != "" && !isManual && !isActive
    ? false
    : isValid && practiceId != ""
    ? true
    : false;
};

export const patientPayload = (values) => {
  return {
    personKey: uuidv4(),
    firstName: values.firstName,
    lastName: values.lastName,
    defaultPhone: values.defaultPhone,
    email: values.email,
    defaultAddress: values.defaultAddress,
    cellPhone: values.defaultPhone == "cell" ? values.phoneNumber : null,
    homePhone: values.defaultPhone == "home" ? values.phoneNumber : null,
    workPhone: values.defaultPhone == "work" ? values.phoneNumber : null,
    mailingAddrLine1:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.addressLine1
        : "",
    mailingAddrLine2:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.addressLine2
        : "",
    mailingCity:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.city : "",
    mailingState:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.state : "",
    mailingZip:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.zip : "",
    billingAddrLine1:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.addressLine1
        : "",
    billingAddrLine2:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.addressLine2
        : "",
    billingCity:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.city : "",
    billingState:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.state : "",
    billingZip:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.zip : "",
    birthDate: new Date(values.birthDate).toLocaleDateString()
  };
};

export const partnerPayload = (values) => {
  return {
    personKey: uuidv4(),
    firstName: values.firstName,
    lastName: values.lastName,
    birthDate: new Date(values.birthDate).toLocaleDateString(),
    defaultPhone: values.defaultPhone == "" ? null : values.defaultPhone,
    email: values.email,
    defaultAddress: values.defaultAddress,
    cellPhone: values.defaultPhone == "cell" ? values.phoneNumber : null,
    homePhone: values.defaultPhone == "home" ? values.phoneNumber : null,
    workPhone: values.defaultPhone == "work" ? values.phoneNumber : null,
    mailingAddrLine1:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.addressLine1
        : null,
    mailingAddrLine2:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.addressLine2
        : null,
    mailingCity:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.city : null,
    mailingState:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.state
        : null,
    mailingZip:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.zip : null,
    billingAddrLine1:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.addressLine1
        : null,
    billingAddrLine2:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.addressLine2
        : null,
    billingCity:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.city : null,
    billingState:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.state
        : null,
    billingZip:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.zip : null
  };
};

export const defaultPracticeValues = {
  linkMedcaseId: "",
  pmId: "",
  tpId: "",
  contactId: "",
  physicianId: "",
  locationId: "",
  practiceId: "",
  patientId: "",
  isAutogenerate: 0,
  isAssist: false,
  isActive: 1
};
const xsValue = [
  {
    len: 3,
    value: 4
  },
  {
    len: 2,
    value: 6
  },
  {
    len: 1,
    value: 12
  }
];
export const getXsValue = (len, mcpShowEstimate, isRefundProgram) => {
  let val;
  if (!mcpShowEstimate && isRefundProgram) {
    len = len - 1;
  }
  xsValue.map((item) => {
    if (item.len == len) {
      val = item.value;
    }
  });
  return val;
};

const getDateFormat = (date) => {
  return date && typeof date === "string" ? date.toString().replace(/-/g, "/") : date;
};

export const removeObjParameters = (data) => {
  const clinicalStages = data.clinicalTreatmentStages;
  clinicalStages.map((ele) => {
    (ele.dateRecorded =
      ele.dateRecorded && moment(new Date(getDateFormat(ele.dateRecorded))).format("yyyy-MM-DD")),
      delete ele.disabled;
  });
  const payload = {
    // New Field
    forecastEvent: data.forecastEvent,
    cycleStartDate:
      data.cycleStartDate &&
      moment(new Date(getDateFormat(data.cycleStartDate))).format("yyyy-MM-DD"),
    retrievalDate:
      data.retrievalDate &&
      moment(new Date(getDateFormat(data.retrievalDate))).format("yyyy-MM-DD"),
    transferDate:
      data.transferDate && moment(new Date(getDateFormat(data.transferDate))).format("yyyy-MM-DD"),
    cycleMiscarriedDate:
      data.cycleMiscarriedDate &&
      moment(new Date(getDateFormat(data.cycleMiscarriedDate))).format("yyyy-MM-DD"),
    cycleDetail: data.cycleDetail,
    // End New Field

    authDate: data.authDate && moment(new Date(getDateFormat(data.authDate))).format("yyyy-MM-DD"),
    performedTp: data.performedTp,
    forecastDate:
      data.forecastDate && moment(new Date(getDateFormat(data.forecastDate))).format("yyyy-MM-DD"),
    authExpDate:
      data.authExpDate && moment(new Date(getDateFormat(data.authExpDate))).format("yyyy-MM-DD"),
    outcomeCategory: data.outcomeCategory,
    outcomeDescription: data.outcomeDescription,
    outcomeDate:
      data.outcomeDate && moment(new Date(getDateFormat(data.outcomeDate))).format("yyyy-MM-DD"),
    edcDate: data.edcDate && moment(new Date(getDateFormat(data.edcDate))).format("yyyy-MM-DD"),
    miscarrigeDate:
      data.miscarrigeDate &&
      moment(new Date(getDateFormat(data.miscarrigeDate))).format("yyyy-MM-DD"),
    clinicalTreatmentStages: clinicalStages,
    clinicalTreatmentAdditionalField: {
      pgt: data.clinicalTreatmentAdditionalField.pgt,
      icsi: data.clinicalTreatmentAdditionalField.icsi,
      eggFreezing: data.clinicalTreatmentAdditionalField.eggFreezing || "",
      noOfFrozonEgg:
        data.clinicalTreatmentAdditionalField.noOfFrozonEgg == ""
          ? 0
          : data.clinicalTreatmentAdditionalField.noOfFrozonEgg,
      transferDate:
        data.clinicalTreatmentAdditionalField.transferDate &&
        moment(new Date(getDateFormat(data.clinicalTreatmentAdditionalField.transferDate))).format(
          "yyyy-MM-DD"
        ),
      frozenEmbryo:
        data.clinicalTreatmentAdditionalField.frozenEmbryo == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.frozenEmbryo,
      transferedEmbryo:
        data.clinicalTreatmentAdditionalField.transferedEmbryo == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.transferedEmbryo,
      remainingEmbryo:
        data.clinicalTreatmentAdditionalField.remainingEmbryo == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.remainingEmbryo,
      normalFrozenEmbroysRemaining:
        data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining,
      verifiedNormalEmbryosRemaining:
        data.clinicalTreatmentAdditionalField.verifiedNormalEmbryosRemaining == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.verifiedNormalEmbryosRemaining,
      clinicalDecision: data.clinicalTreatmentAdditionalField.clinicalDecision,
      requestedService: data.clinicalTreatmentAdditionalField.requestedService,
      cycleStartDay:
        data.clinicalTreatmentAdditionalField.cycleStartDay &&
        moment(new Date(getDateFormat(data.clinicalTreatmentAdditionalField.cycleStartDay))).format(
          "yyyy-MM-DD"
        ),
      comment: data?.clinicalTreatmentAdditionalField?.comment || null,
      fetCycle: data?.clinicalTreatmentAdditionalField?.fetCycle,
      retrievalCycle: data?.clinicalTreatmentAdditionalField?.retrievalCycle
    }
  };
  return payload;
};
const gender = { F: "Female", M: "Male" };
export const getGengerFullName = (sex) => {
  return gender[sex];
};
export const isNameValid = (name) => {
  return !/\d/.test(name);
};

export const patientFormValues = {
  personId: uuidv4(),
  firstName: "",
  lastName: "",
  streetLine1: "",
  streetLine2: "",
  city: "",
  state: "",
  zip: "",
  country: "USA",
  cellPhone: "",
  homePhone: "",
  workPhone: "",
  additionalPhone: "",
  gender: "F",
  birthDate: "",
  email: "",
  practiceId: localStorage.getItem("practiceId") ? localStorage.getItem("practiceId") : "",
  defaultAddress: "Mailing",
  defaultPhone: "cell",
  phoneNumber: "",
  locationId: "",
  physicianId: "",
  contactId: "",
  ivfMD: "",
  ivfMDFirstName: "",
  ivfMDLastName: "",
  ivfMDMidInitial: "",
  mdNPI: "",
  activePartner: {}
};
export const patientFormPayload = (values, activePartner) => {
  console.log("check 4 values", values);

  return {
    firstName: values.firstName,
    lastName: values.lastName,
    mailingAddrLine1:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.streetLine1
        : "",
    mailingAddrLine2:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.streetLine2
        : "",
    mailingCity:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.city : "",
    mailingState:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.state : "",
    mailingZip:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing" ? values.zip : "",
    mailingCountry:
      values.defaultAddress == "Mailing" || values.defaultAddress == "mailing"
        ? values.country
        : "",
    billingAddrLine1:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.streetLine1
        : "",
    billingAddrLine2:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.streetLine2
        : "",
    billingCity:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.city : "",
    billingState:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.state : "",
    billingZip:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing" ? values.zip : "",
    billingCountry:
      values.defaultAddress == "Billing" || values.defaultAddress == "billing"
        ? values.country
        : "",
    cellPhone: values.defaultPhone == "cell" ? values.phoneNumber : "",
    homePhone: values.defaultPhone == "home" ? values.phoneNumber : "",
    workPhone: values.defaultPhone == "work" ? values.phoneNumber : "",
    additionalPhone: values.defaultPhone == "additional" ? values.phoneNumber : "",
    gender: values.gender,
    birthDate: moment(values.birthDate).format("MM/DD/YYYY"),
    email: values.email,
    practiceId: values.practiceId,
    defaultAddress: values.defaultAddress,
    defaultPhone: values.defaultPhone,
    ivfMD: values.ivfMD,
    ivfMDFirstName: values.ivfMDFirstName,
    ivfMDLastName: values.ivfMDLastName,
    ivfMDMidInitial: values.ivfMDMidInitial,
    mdNPI: values.mdNPI,
    activePartner: {
      active: activePartner === "Yes" ? true : false
    }
  };
};

export const refundApplicationValidation = (name, value) => {
  if (!isNaN(value)) {
    if (value < 0) {
      return true;
    }
    if (name === "raMaxAMH") {
      if (value > 50) {
        return true;
      }
    }
    if (name === "raE2") {
      if (value > 2000) {
        return true;
      }
    }
    if (name === "raFSH") {
      if (value > 100) {
        return true;
      }
    }
    if (name === "raPrevMiscarriage") {
      if (value > 15) {
        return true;
      }
    }
  }
  return false;
};

export const msgConfig = [
  {
    title: "INBOX",
    url: "/messages/inbox",
    text: "Inbox",
    icon: "/Assets/payment.png"
  },
  {
    title: "DRAFTS",
    url: "/messages/drafts",
    text: "Drafts",
    icon: "/Assets/payment.png"
  },
  {
    title: "SENT",
    url: "/messages/sent",
    text: "Sent",
    icon: "/Assets/payment.png"
  },

  {
    title: "DELETED",
    url: "/messages/deleted",
    text: "Deleted",
    icon: "/Assets/payment.png"
  }
];
export const CLINICAL_DECISION = ["Approved", "Pending"];

export const getOutcomeFormData = (data, event) => {
  let formData = {
    clinicalTreatmentId: data?.clinicalTreatmentId || "",
    authDate: data?.authDate,
    performedTp: data?.performedTp || "",
    performedTpName: data?.performedTpName || "",
    authorizedTpName: data?.authorizedTpName || "",
    forecastDate: data?.forecastDate,
    outcomeCategory: data?.outcomeCategory,
    outcomeDescription: data?.outcomeDescription,
    outcomeDate: data?.outcomeDate || moment().format("YYYY-MM-DD"),
    edcDate: data?.edcDate,
    miscarrigeDate: data?.miscarrigeDate,
    program: data?.program || "",
    authExpDate: data?.authExpDate,
    enrollmentDate: data?.enrollmentDate,
    clinicalTreatmentNo: data?.clinicalTreatmentNo || "",
    clinicalCycleNo: data?.clinicalCycleNo || "",
    requestedBy: data?.requestedBy || "",
    user: data?.user || "",
    requestedDate: data?.requestedDate,
    gdmId: data?.gdmId || "",
    clinicalTreatmentStages: data?.clinicalTreatmentStages,
    clinicalTreatmentAdditionalField: {
      clinicalTreatmentId: data?.clinicalTreatmentAdditionalField.clinicalTreatmentId || "",
      pgt: data?.clinicalTreatmentAdditionalField.pgt || "",
      icsi: data?.clinicalTreatmentAdditionalField.icsi || "",
      eggFreezing: data?.clinicalTreatmentAdditionalField.eggFreezing
        ? data?.clinicalTreatmentAdditionalField.eggFreezing
        : false,
      noOfFrozonEgg: parseInt(data?.clinicalTreatmentAdditionalField.noOfFrozonEgg),
      transferDate: data?.clinicalTreatmentAdditionalField.transferDate,
      frozenEmbryo: parseInt(data?.clinicalTreatmentAdditionalField.frozenEmbryo)
        ? parseInt(data?.clinicalTreatmentAdditionalField.frozenEmbryo)
        : "",
      transferedEmbryo: parseInt(data?.clinicalTreatmentAdditionalField.transferedEmbryo)
        ? parseInt(data?.clinicalTreatmentAdditionalField.transferedEmbryo)
        : "",
      remainingEmbryo: parseInt(data?.clinicalTreatmentAdditionalField.remainingEmbryo)
        ? parseInt(data?.clinicalTreatmentAdditionalField.remainingEmbryo)
        : "",
      normalFrozenEmbroysRemaining: parseInt(
        data?.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining
      )
        ? parseInt(data?.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining)
        : null,
      verifiedNormalEmbryosRemaining: parseInt(
        data?.clinicalTreatmentAdditionalField.verifiedNormalEmbryosRemaining
      )
        ? parseInt(data?.clinicalTreatmentAdditionalField.verifiedNormalEmbryosRemaining)
        : null,
      clinicalDecision: data?.clinicalTreatmentAdditionalField.clinicalDecision,
      requestedService: data?.clinicalTreatmentAdditionalField.requestedService,
      cycleStartDay: data?.clinicalTreatmentAdditionalField.cycleStartDay,
      comment: data?.clinicalTreatmentAdditionalField.comment || "",
      retrievalCycle: data?.clinicalTreatmentAdditionalField.retrievalCycle,
      fetCycle: data?.clinicalTreatmentAdditionalField.fetCycle
    },
    cycleDetail:
      event == "get"
        ? data?.cycleDetail
          ? data?.cycleDetail
          : JSON.parse(data.cycleDetailStr)
        : data?.cycleDetail,
    cycleStartDate: data?.cycleStartDate,
    retrievalDate: data?.retrievalDate,
    transferDate: data?.transferDate,
    programRefundType: data?.programRefundType,
    clinicalCycleType: data?.clinicalCycleType
  };
  return formData;
};
export const InitialValuesForMessage = {
  messageId: null,
  conversationId: null,
  subject: "",
  body: "",
  type: null,
  fromUserId: "",
  assignedTo: null,
  status: "",
  hasAttachment: false,
  notes: null,
  srcMessageId: null,
  sentDate: null,
  updationDate: null,
  messageAttachment: [],
  messageRecipient: []
};

// export const clinicalTreatmentOutcomeFormData = [
//   {
//     clinicalTreatmentId: "",
//     authDate: "",
//     performedTp: "",
//     performedTpName: "default",
//     authorizedTpName: "",
//     forecastDate: "",
//     outcomeCategory: "",
//     outcomeDescription: "",
//     outcomeDate: "",
//     edcDate: "",
//     miscarrigeDate: "",
//     program: "",
//     authExpDate: "",
//     enrollmentDate: "",
//     clinicalTreatmentNo: "",
//     clinicalCycleNo: "",
//     requestedBy: "string",
//     user: "string",
//     requestedDate: "",
//     gdmId: "",
//     clinicalTreatmentStages: [
//       {
//         stageId: "",
//         stageName: "",
//         stageCompleted: true,
//         stageCancelled: true,
//         dateRecorded: ""
//       }
//     ],
//     clinicalTreatmentAdditionalField: {
//       clinicalTreatmentId: "",
//       pgt: false,
//       icsi: false,
//       eggFreezing: false,
//       noOfFrozonEgg: 0,
//       transferDate: "",
//       frozenEmbryo: 0,
//       transferedEmbryo: 0,
//       remainingEmbryo: 0,
//       normalFrozenEmbroysRemaining: 0,
//       clinicalDecision: "",
//       requestedService: "",
//       cycleStartDay: "",
//       comment: ""
//     }
//   }
// ];

export const handleValidateSaveOutcome = (values, clinicalTreatmentId) => {
  let data = values?.filter((item) => {
    return item.clinicalTreatmentId === clinicalTreatmentId;
  })[0];
  let isValid = true;
  console.log("data==>", data);
  // Destructure values for easier access, including new fields like performedTp and retrievalCycle
  let {
    outcomeCategory,
    outcomeDate,
    authorizedTpName, // IVF or FET
    cycleStartDate,
    retrievalDate,
    transferDate,
    miscarrigeDate,
    clinicalTreatmentAdditionalField,
    clinicalTreatmentStages, // Stages performed
    performedTp // performedTreatment renamed to performedTp
  } = data || {};

  let remainingEmbryo = clinicalTreatmentAdditionalField?.remainingEmbryo;
  let transferredEmbryo = clinicalTreatmentAdditionalField?.transferedEmbryo;
  let frozenEmbryo = clinicalTreatmentAdditionalField?.frozenEmbryo;
  let retrievalCycle = clinicalTreatmentAdditionalField?.retrievalCycle; // Added retrievalCycle field
  let normalFrozenEmbryosRemaining = clinicalTreatmentAdditionalField?.normalFrozenEmbroysRemaining; // Added normalFrozenEmbroysRemaining field
  let requestedService = clinicalTreatmentAdditionalField?.requestedService; // Added normalFrozenEmbroysRemaining field

  const isStageCompletedOrCancelled = clinicalTreatmentStages?.some(
    (stage) => stage.stageCompleted || stage.stageCancelled
  );

  //const isAcknowledged = data?.acknowledge; // Assuming there's a field for "I Acknowledge"

  // General logic based on outcome category
  switch (outcomeCategory) {
    case "No Status":
      // No required fields
      break;

    case "Cycle Reset":
      if (!outcomeCategory || !outcomeDate) {
        isValid = false;
      }
      break;

    case "Cycle Canceled":
      if (
        !performedTp ||
        !outcomeCategory ||
        !outcomeDate ||
        !retrievalCycle || // Retrieval cycle is required
        !isStageCompletedOrCancelled ||
        !cycleStartDate
      ) {
        isValid = false;
      }
      if (authorizedTpName === "IVF" && !retrievalDate) {
        isValid = false; // IVF case with missing retrieval cycle info
      }
      break;

    case "Pregnant":
    case "Not Pregnant":
    case "Not Pregnant - Miscarried":
    case "Not Pregnant - Biochemical":
    case "Not Pregnant - Ectopic":
    case "Not Pregnant - Still Born":
    case "Not Pregnant - Loss of Newborn":
      if (
        !performedTp ||
        !outcomeCategory ||
        !outcomeDate ||
        !isStageCompletedOrCancelled ||
        !cycleStartDate ||
        !retrievalDate ||
        !transferDate ||
        transferredEmbryo === undefined ||
        transferredEmbryo === null ||
        frozenEmbryo === undefined ||
        frozenEmbryo === null ||
        remainingEmbryo === undefined ||
        remainingEmbryo === null
      ) {
        isValid = false;
      }
      break;

    case "Not Pregnant - Freeze All":
    case "Not Pregnant - No Transfer":
      if (
        !performedTp ||
        !outcomeCategory ||
        !outcomeDate ||
        !isStageCompletedOrCancelled ||
        !cycleStartDate ||
        !retrievalDate ||
        frozenEmbryo === undefined ||
        frozenEmbryo === null ||
        remainingEmbryo === undefined ||
        remainingEmbryo === null
      ) {
        isValid = false;
      }
      break;

    case "Miscarried":
      if (
        !performedTp ||
        !outcomeCategory ||
        !outcomeDate ||
        !miscarrigeDate ||
        !isStageCompletedOrCancelled ||
        !cycleStartDate ||
        !retrievalDate ||
        !transferDate ||
        transferredEmbryo === undefined ||
        transferredEmbryo === null ||
        frozenEmbryo === undefined ||
        frozenEmbryo === null ||
        remainingEmbryo === undefined ||
        remainingEmbryo === null
      ) {
        isValid = false;
      }
      break;

    default:
      break;
  }

  // Additional Condition for Requested Services (Retrieval or FET)
  if (
    ["Retrieval", "FET"].includes(requestedService) &&
    (normalFrozenEmbryosRemaining === undefined ||
      normalFrozenEmbryosRemaining === null ||
      normalFrozenEmbryosRemaining === "")
  ) {
    isValid = false;
  }

  return !isValid; // Return false if the form is invalid, true if valid
};

// export const handleValidateSaveOutcome = (values, clinicalTreatmentId) => {
//   let data = values?.filter((item) => {
//     return item.clinicalTreatmentId === clinicalTreatmentId;
//   })[0];
//   console.log("data==>", data);
//   let isValid = true;
//   let remainingEmbryo = data?.clinicalTreatmentAdditionalField?.remainingEmbryo;
//   if (
//     data.outcomeCategory &&
//     data.outcomeCategory != "No Status" &&
//     data.outcomeDate !== null &&
//     (remainingEmbryo === undefined || remainingEmbryo === null || remainingEmbryo === "")
//   ) {
//     isValid = false;
//   }

//   if (data.outcomeCategory == "Miscarried" && !data.miscarrigeDate) {
//     isValid = false;
//   }
//   // First Condition : Cycle Start Date
//   if (
//     [
//       "Pregnant",
//       "Not Pregnant",
//       "Miscarried",
//       "Cycle Canceled",
//       "Not Pregnant - Miscarried",
//       "Not Pregnant - Biochemical",
//       "Not Pregnant - Ectopic",
//       "Not Pregnant - Freeze All",
//       "Not Pregnant - No Transfer",
//       "Not Pregnant - Still born",
//       "Not Pregnant - Loss of Newborn"
//     ].indexOf(data.outcomeCategory) > -1 &&
//     !data.cycleStartDate
//   ) {
//     isValid = false;
//   }
//   // Second Condition : Retrieval Date
//   if (
//     [
//       "Pregnant",
//       "Not Pregnant",
//       "Miscarried",
//       "Not Pregnant - Miscarried",
//       "Not Pregnant - Biochemical",
//       "Not Pregnant - Ectopic",
//       "Not Pregnant - Freeze All",
//       "Not Pregnant - No Transfer",
//       "Not Pregnant - Still born",
//       "Not Pregnant - Loss of Newborn"
//     ].indexOf(data.outcomeCategory) > -1 &&
//     data.authorizedTpName != "FET" &&
//     !data.retrievalDate
//   ) {
//     isValid = false;
//   }
//   // Third Condition : Transfer Date
//   if (
//     [
//       "Pregnant",
//       "Not Pregnant",
//       "Miscarried",
//       "Not Pregnant - Miscarried",
//       "Not Pregnant - Biochemical",
//       "Not Pregnant - Ectopic",
//       "Not Pregnant - Still born",
//       "Not Pregnant - Loss of Newborn"
//     ].indexOf(data.outcomeCategory) > -1 &&
//     !data.transferDate
//   ) {
//     isValid = false;
//   }
//   // Fourth Condition : # Transferred
//   let transferedEmbryo = data?.clinicalTreatmentAdditionalField?.transferedEmbryo;
//   if (
//     [
//       "Pregnant",
//       "Not Pregnant",
//       "Miscarried",
//       "Not Pregnant - Miscarried",
//       "Not Pregnant - Biochemical",
//       "Not Pregnant - Ectopic",
//       "Not Pregnant - Still born",
//       "Not Pregnant - Loss of Newborn"
//     ].indexOf(data.outcomeCategory) > -1 &&
//     (transferedEmbryo === undefined || transferedEmbryo === null || transferedEmbryo === "")
//   ) {
//     isValid = false;
//   }
//   // Fifth Condition : # Frozon
//   let frozenEmbryo = data?.clinicalTreatmentAdditionalField?.frozenEmbryo;
//   if (
//     [
//       "Pregnant",
//       "Not Pregnant",
//       "Miscarried",
//       "Not Pregnant - Miscarried",
//       "Not Pregnant - Biochemical",
//       "Not Pregnant - Ectopic",
//       "Not Pregnant - Still born",
//       "Not Pregnant - Loss of Newborn",
//       "Not Pregnant - Freeze All",
//       "Not Pregnant - No Transfer"
//     ].indexOf(data.outcomeCategory) > -1 &&
//     (frozenEmbryo === undefined || frozenEmbryo === null || frozenEmbryo === "")
//   ) {
//     isValid = false;
//   }

//   // if (
//   //   [
//   //     "Not Pregnant",
//   //     "Not Pregnant - Miscarried",
//   //     "Not Pregnant - Biochemical",
//   //     "Not Pregnant - Ectopic",
//   //     "Not Pregnant - Freeze All",
//   //     "Not Pregnant - No Transfer",
//   //     "Not Pregnant - Still born",
//   //     "Not Pregnant - Loss of Newborn",
//   //     "Pregnant",
//   //     "Miscarried"
//   //   ].indexOf(data.outcomeCategory) > -1 &&
//   //   !(
//   //     data.retrievalDate != null &&
//   //     data.transferDate != null &&
//   //     data.clinicalTreatmentAdditionalField &&
//   //     data.clinicalTreatmentAdditionalField.transferedEmbryo &&
//   //     data.clinicalTreatmentAdditionalField.frozenEmbryo
//   //   )
//   // ) {
//   //   isValid = false;
//   // }

//   if (data.outcomeCategory == "Pregnant" && !data.outcomeDate) {
//     isValid = false;
//   }

//   // if (data.outcomeCategory == "Not Pregnant - Miscarried" && !data.miscarrigeDate) {
//   //   isValid = false;
//   // }

//   return isValid ? false : true;
// };
export const handleEnableTransferDate = (values, clinicalTreatmentId) => {
  let data = values?.filter((item) => {
    return item.clinicalTreatmentId === clinicalTreatmentId;
  })[0];

  let stagesChange = data?.clinicalTreatmentStages.filter(
    (ele) => ele.stageName == "Transfer" && ele.stageCompleted != false
    // ele.stageCancelled != false &&
    // ele.dateRecorded != null
  );
  let validateTransferDate =
    stagesChange?.length > 0 ? (stagesChange[0]?.stageName == "Transfer" ? true : false) : false;
  return validateTransferDate == true ? false : true;
};
export const getURLEndpoint = (url) => {
  return /(^https?:\/\/[^\\/]+)/g.exec(url)[1];
};
export const pathComponent = (invokeUrl) => {
  return invokeUrl.substring(getURLEndpoint(invokeUrl).length);
};

export const validateFileSize = (file) => {
  const MAX_FILE_SIZE = 10240; // 10MB
  if (!file) {
    return ["Please choose a file", true];
  }
  const fileSizeKiloBytes = file.size / 1024;
  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    return ["File size is greater than maximum limit", false];
  }
  return ["File uploaded successfully", true];
};
// export const validateFileType = (file) => {
//   if (!file) {
//     return ["Please choose a file", true];
//   }
//   const selectedFileType = file.type; // 10MB
//   if (
//     selectedFileType !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
//     selectedFileType !== "application/msword" &&
//     selectedFileType !== "text/csv" &&
//     selectedFileType !== "application/pdf"
//   ) {
//     return ["File type not supported.", false];
//   }
//   return ["File uploaded successfully", true];
// };
