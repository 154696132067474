import * as actionType from "../actions/ActionTypes";

const initialState = {
  practices: [],
  addPractice: null,
  editPractice: null,
  loading: false,
  error: null,
  success: null,
  practiceId: null,
  fileImport: null,
  importedPractedId: null
};

const practiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PRACTICE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PRACTICE_LIST_SUCCESS: {
      return {
        ...state,
        practices: action.payload,
        loading: false
      };
    }
    case actionType.PRACTICE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_PRACTICE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.POST_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileImport: action.payload,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_PRACTICE_SUCCESS: {
      return {
        ...state,
        addPractice: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_PRACTICE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_PRACTICE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PRACTICE_SUCCESS: {
      return {
        ...state,
        editPractice: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.IMPORT_PRACTICE_DATA_SUCCESS: {
      return {
        ...state,
        importedPractedId: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_PRACTICE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.IMPORT_PRACTICE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.POST_FILE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_PRACTICE_LIST: {
      return {
        ...state,
        addPractice: null,
        editPractice: null,
        loading: null
      };
    }
    case actionType.SET_PRACTICE_ID: {
      return {
        ...state,
        practiceId: action.payload,
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default practiceReducer;
