import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Modal
} from "@mui/material";
import { getPractices, getLocations } from "../../admin/actions";
import * as actionType from "../actions";
// import LoadingSpinner from "../../shared/components/LoadingSpinner";
import QuickAdd from "../../shared/components/QuickAdd";

const MedcaseHeader = (props) => {
  const dispatch = useDispatch();
  const { locations, actions, loading } = props;
  const [practiceId, setPracticeId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationList, setLocations] = useState(null);
  const practices = useSelector((state) => state.practices.practices);
  const practicesLoading = useSelector((state) => state.practices.loading);
  useEffect(() => {
    if (!practices.length) dispatch(getPractices());
  }, []);
  useEffect(() => {
    setLocations(locations);
  }, [locations]);
  const handlePracticeChange = (event) => {
    actions.resetMedCaseList();
    setPracticeId(event.target.value);
    dispatch(getLocations(event.target.value));
  };
  const handleLocationChange = (event) => {
    setLocationId(event.target.value);
  };

  const handleSearch = (e, search) => {
    actions.resetMedCaseList();
    if (search == "") {
      return;
    }

    actions.getMedcaseList({
      practiceId: practiceId,
      nameOrId: search,
      clinicalLocation: locationId
    });
  };

  /*const handleTextFieldChange = (e) => {
    setSearchTxt(e.target.value);
  };*/
  return (
    <>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Select Practice*</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <Select
                fullWidth
                name="practiceId"
                value={practiceId}
                onChange={handlePracticeChange}
                className="stage-practice-dropdown">
                {practices &&
                  practices.map((item, index) => {
                    return (
                      <MenuItem value={item.practiceId} key={index}>
                        {item.name} -{item.practiceId}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Select Location</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <Select
                fullWidth
                name="locationId"
                value={locationId}
                onChange={handleLocationChange}
                className="stage-practice-dropdown">
                {locationList &&
                  locationList.map((item, index) => {
                    return (
                      <MenuItem value={item.locationId} key={index}>
                        {item.name} -{item.locationId}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Grid container>
        <Grid item xs={10}>
          <QuickAdd
            clearSearchClick={() => actions.resetMedCaseList()}
            text={2}
            search={8}
            handleSearchClick={handleSearch}
            mandatory="true"
          />
        </Grid>
      </Grid>

      {/* {practicesLoading || loading ? (
          <Stack justifyContent="center" p={5} alignItems="center">
            <CircularProgress size={50} />
          </Stack>
        ) : null} */}

      {practicesLoading || loading ? (
        <Modal
          open={practicesLoading || loading}
          backdrop="static"
          aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};
const mapStateToProps = ({ locationReducer }) => {
  return {
    locations: locationReducer.locations,
    loading: locationReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getLocations: (practiceId) => {
      dispatch(getLocations(practiceId));
    },
    getMedcaseList: (payload) => {
      dispatch(actionType.getMedcaseList(payload));
    },
    resetMedCaseList: () => {
      dispatch(actionType.resetMedCaseList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(MedcaseHeader);
