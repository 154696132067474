import { all, call, put, takeEvery } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import * as actionTypes from "../actions/ActionTypes";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";
//get fapList
function* fetchFapList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "0",
      limit: "10000",
      ...payload
    };
    const requestBody = createRequestBody("get", `/patientTreatmentCycles`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FAP_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FAP_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* fetchFapListSaga() {
  yield takeEvery(actionTypes.FAP_LIST_REQUESTED, fetchFapList);
}

//get clinical treatment
function* getClinicalData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/clinicalTreatmentOutcomes/${payload.clinicalTreatmentId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CLINICAL_TREATMENT_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CLINICAL_TREATMENT_DATA_FAILED,
        message: error.message
      });
    }
  }
}

function* fetchClinicalTreatmentData() {
  yield takeEvery(actionTypes.CLINICAL_TREATMENT_DATA_REQUESTED, getClinicalData);
}

//Save edited outcome form data
function* updateOutcomeFormDetails(action) {
  const { payload } = action;
  const { medcaseId, clinicalTreatmentId, formData, formButtonType } = payload;
  console.log("formButtonType", formButtonType);
  console.log("formData", formData);

  let apiUrl = `/medcases/${medcaseId}/clinicalTreatmentOutcomes/${clinicalTreatmentId}`;
  if (formButtonType === "SaveAndClose") {
    apiUrl = `/medcases/${medcaseId}/clinicalTreatmentOutcomes/edit/${clinicalTreatmentId}`;
  }

  try {
    const requestBody = createRequestBody("put", apiUrl, "", formData);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* updateOutcomeFormSaga() {
  yield takeEvery(
    actionTypes.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_REQUESTED,
    updateOutcomeFormDetails
  );
}

//Save Authorization form data
function* editedAuthFormSave(action) {
  const { payload } = action;
  const { medcaseId, clinicalTreatmentId, values } = payload;

  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${medcaseId}/clinicalTreatmentOutcomes/${clinicalTreatmentId}/authorizations`,
      "",
      values
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDITED_AUTH_DETAILS_SAVE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDITED_AUTH_DETAILS_SAVE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editedAuthorizationSaga() {
  yield takeEvery(actionTypes.EDITED_AUTH_DATA_SAVE_REQUESTED, editedAuthFormSave);
}

function* fetchEditProgramFapVisibility(action) {
  const { payload } = action;
  const { practiceId, estimateId, estimateTpId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/fertilityAccessPrograms/fapVisibility`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_PROGRAM_FAP_VISIBILITY_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_PROGRAM_FAP_VISIBILITY_FAILED,
        message: error.message
      });
    }
  }
}

function* fetchEditProgramFapVisibilitySaga() {
  yield takeEvery(actionTypes.EDIT_PROGRAM_FAP_VISIBILITY_REQUESTED, fetchEditProgramFapVisibility);
}
function* addEditProgramFapVisibility(action) {
  const { payload } = action;
  const { practiceId, estimateId, estimateTpId, data } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/fertilityAccessPrograms/fapVisibility`,
      "",
      data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_FAP_VISIBILITY_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_FAP_VISIBILITY_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addEditProgramFapVisibilitySaga() {
  yield takeEvery(actionTypes.ADD_FAP_VISIBILITY_REQUESTED, addEditProgramFapVisibility);
}

function* fetchTreatmentPlanList(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_TREATMENT_PLAN_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_TREATMENT_PLAN_LIST_FAILED, errorMsg: error.message });
    }
  }
}

function* getTreatmentPlanListSaga() {
  yield takeEvery(actionTypes.GET_TREATMENT_PLAN_LIST_REQUESTED, fetchTreatmentPlanList);
}

function* fetchMedcasePatientInfo(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/medcaseDetailsHeader`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_MEDCASE_PATIENT_INFO_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_MEDCASE_PATIENT_INFO_FAILED,
        message: error.message
      });
    }
  }
}

function* fetchMedcasePatientInfoSaga() {
  yield takeEvery(actionTypes.GET_MEDCASE_PATIENT_INFO_REQUESTED, fetchMedcasePatientInfo);
}

function* fetchPatientInfoUsingPracticeIds(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person/${payload.personId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.SET_PATIENT_DETAILS,
      payload: response.data
    });
  } catch (error) {
    // if (appAuthencationCheck(error) === true) {
    //   yield put({ type: actionTypes.PERSON_DATA_FAILED, message: error.message });
    // }
  }
}

function* fetchPatientInfoUsingPracticeId() {
  yield takeEvery(actionTypes.GET_PERSON_BY_ID, fetchPatientInfoUsingPracticeIds);
}
export default function* fapSaga() {
  yield all([
    fetchFapListSaga(),
    fetchEditProgramFapVisibilitySaga(),
    addEditProgramFapVisibilitySaga(),
    updateOutcomeFormSaga(),
    editedAuthorizationSaga(),
    fetchClinicalTreatmentData(),
    getTreatmentPlanListSaga(),
    fetchMedcasePatientInfoSaga(),
    fetchPatientInfoUsingPracticeId()
  ]);
}
