import React, { useEffect, useState } from "react";
import QuickAdd from "../../shared/components/QuickAdd";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { contractColumns } from "../constants";
import { Box, Button, Typography, Stack, Grid, Autocomplete, TextField } from "@mui/material";
import WarningPopUp from "./modals/WarningPopUp";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const Contract = (props) => {
  const { actions, contractList, loading, success, error, metaData } = props;
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [searchTxt, setSearchTxt] = useState("");
  const [disableBtns, setDisableBtns] = useState(true);
  // const [url, setUrl] = useState("");
  const [selectedContractId, setselectedContractId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  let formData = {
    medcaseStatus: "All",
    contractStatus: "All",
    pgtaStatus: "All",
    paymentStatus: "All",

    fromDate: moment().subtract(90, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD")
  };

  const [values, setValues] = useState(formData);

  function handleSearch(e, name, value) {
    if (value !== null || value !== undefined || value !== "")
      setValues({ ...values, [name]: value });
  }

  useEffect(() => {
    let payload = {
      medcaseStatus: values.medcaseStatus,
      contractStatus: values.contractStatus,
      pgtaStatus: values.pgtaStatus,
      paymentStatus: values.paymentStatus,
      searchTxt: "",

      fromDate: values.fromDate,
      toDate: values.toDate
    };
    actions.getContractList(payload);
    actions.getMetaData();
  }, []);

  useEffect(() => {
    if (success?.documentUrl) {
      // console.log(success, "Success");
      var a = document.createElement("a");
      a.href = success.documentUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetDocumentDownload();
    }
    if (error) {
      setIsOpen(true);
    }
  }, [success, error]);

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      contractGenerated: row.contractGenerated
        ? new Date(row.contractGenerated).toLocaleDateString("en-US")
        : "",
      reminderDate: row.reminderDate ? new Date(row.reminderDate).toLocaleDateString("en-US") : ""
    }));
  };

  useEffect(() => {
    const rows =
      contractList.length !== 0
        ? contractList.map((item, index) => {
            item.id = index;
            return {
              ...item,
              contractGenerated: item.contractGenerated
                ? new Date(item.contractGenerated).toLocaleDateString("en-US")
                : "",
              reminderDate: item.reminderDate
                ? new Date(item.reminderDate).toLocaleDateString("en-US")
                : ""
            };
          })
        : [];

    const csvDataMode = prepareCsvData(rows);
    console.log("csvDataMode: ", csvDataMode);
    setCsvData(csvDataMode);
  }, [contractList]);

  const handleSearchMain = (e, search) => {
    setSearchTxt(search);
    console.log(searchTxt);
    let payload = {
      medcaseStatus: values.medcaseStatus,
      contractStatus: values.contractStatus,
      pgtaStatus: values.pgtaStatus,
      paymentStatus: values.paymentStatus,
      searchTxt: search,

      fromDate: values.fromDate,
      toDate: values.toDate
    };

    actions.getContractList(payload);
  };
  const selectRow = (params) => {
    setDisableBtns(false);
    setselectedContractId(params.row.contractId);
    // setUrl(`/contract/${params.row.contractId}/documentUrl?limit=100&offset=`);
  };
  const handleDownloadUrl = () => {
    // handleDownload(url, "document.pdf");
    const payload = { contractId: selectedContractId };
    // console.log(payload, "ContractId");
    actions.contractDocumentDownload(payload);
  };
  // const handleDownload = (url, filename) => {
  //   fetch(url)
  //     .then((res) => {
  //       return res.blob();
  //     })
  //     .then((data) => {
  //       var a = document.createElement("a");
  //       a.href = window.URL.createObjectURL(data);
  //       a.download = filename;
  //       a.click();
  //     });
  // };
  return (
    <>
      <Box className="align-center pad-10 teal-bg-color m-l-10">
        <Grid container>
          <Grid item xs={10}>
            <QuickAdd text={1} search={7} handleSearchClick={handleSearchMain} />
          </Grid>

          <Grid container className="align-center mt-10">
            <Grid item xs={12} sm={1.5}>
              <Typography>Medcase Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="medcaseStatus"
                value={values?.medcaseStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item
                    };
                  })
                }
                onChange={(e, value) => {
                  handleSearch(e, "medcaseStatus", value?.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Medcase Status..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={12} sm={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Contract Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="contractStatus"
                value={values?.contractStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.contractStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item
                    };
                  })
                }
                onChange={(e, value) => {
                  handleSearch(e, "contractStatus", value?.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Contract Status..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Grid>
          </Grid>

          <Grid container className="align-center mt-10">
            <Grid item xs={12} sm={1.5}>
              <Typography>PGTA Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="pgtaStatus"
                value={values?.pgtaStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.pgtaAnalysisStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item
                    };
                  })
                }
                onChange={(e, value) => {
                  handleSearch(e, "pgtaStatus", value?.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="PGTA Status..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={12} sm={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Patient Payment Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="paymentStatus"
                value={values?.paymentStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.paymentStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item
                    };
                  })
                }
                onChange={(e, value) => {
                  handleSearch(e, "paymentStatus", value?.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Status..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Grid>
          </Grid>

          <Grid container className="align-center mt-10">
            <Grid item xs={12} sm={1.5}>
              <Typography> From Date</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  // views={["day", "year", "month"]}
                  disableFuture
                  value={values.fromDate}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, fromDate: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={12} sm={1.5}>
              <Typography>To Date</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  // views={["day", "year", "month"]}
                  disableFuture
                  value={values.toDate}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, toDate: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="m-r-8 m-l-8">
        <Stack direction="row" className="grid-header">
          <Typography>Contract List</Typography>
          <Stack direction="row" className="modal-bottom-section" spacing={2}>
            {/* <Button variant="contained" className="btn-usf" disabled={disableBtns}>
              Send Remainder
            </Button>
            <Button variant="contained" className="btn-usf" disabled={disableBtns}>
              Counter Sign
            </Button>
            <Button variant="contained" className="btn-usf" disabled={disableBtns}>
              Resend
            </Button> */}
            <Button
              variant="contained"
              className="btn-usf"
              disabled={disableBtns}
              onClick={handleDownloadUrl}>
              View Contract
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={csvData}
            rowCount={csvData.length}
            columns={contractColumns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            onRowClick={selectRow}
            isExport={true}
          />
        </Box>
      </Box>
      <WarningPopUp
        open={isOpen}
        closeModal={() => {
          setIsOpen(false);
          actions.resetDocumentDownload();
        }}
        error={error}
      />
    </>
  );
};

const mapStateToProps = ({ contractReducer, caseUpdateReducer }) => {
  return {
    contractList: contractReducer.contractList,
    loading: contractReducer.loading,
    success: contractReducer.successMsg,
    error: contractReducer.errorDownload,

    metaData: caseUpdateReducer.metaData
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getContractList: (payload) => {
      dispatch(actionType.getContractList(payload));
    },
    contractDocumentDownload: (payload) => {
      dispatch(actionType.contractDocumentDownload(payload));
    },
    resetDocumentDownload: () => {
      dispatch(actionType.resetContractDocumentDownload());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
