import React, { useEffect, useState } from "react";
import { Box, Stack, TextField, Grid, Typography, Button /*Autocomplete*/ } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as actionTypeFromTrak from "../../trak/actions";
import { connect } from "react-redux";
import moment from "moment";
//import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import { getPractices } from "../../admin/actions";
import QuickAdd from "../../shared/components/QuickAdd";

const PracticeEnrollments = (props) => {
  const formData = {
    enrollment_date_from: moment().startOf("month").format("YYYY-MM-DD"),
    enrollment_date_to: moment().format("YYYY-MM-DD")
    // practiceId: null,
    // enrolledProgram: "ALL"
  };
  //   const ProgramType = [
  //     { key: "All", value: "All" },
  //     { key: "REFUND", value: "IVF Refund" },

  //     { key: "NON_REFUND", value: "IVF Non Refund" },
  //     { key: "PGTA", value: "PGTA" }
  //   ];
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const { enrollmentReportList = [], /*practices*/ actions, loading } = props;
  const callAPI = () => {
    const localStoragePracticeId = localStorage.getItem("practiceId");
    actions.getEnrollmentReport({
      enrollment_date_from: values.enrollment_date_from,
      enrollment_date_to: values.enrollment_date_to,
      practiceId: localStoragePracticeId ? localStoragePracticeId : "",
      //   enrolledProgram: values.enrolledProgram
      check: "Workspace"
    });
  };
  const rows = enrollmentReportList.map((item, index) => {
    item.id = index;
    return item;
  });
  useEffect(() => {
    // actions.getPractices();
    const localStoragePracticeId = localStorage.getItem("practiceId");
    actions.getEnrollmentReport({
      enrollment_date_from: values.enrollment_date_from,
      enrollment_date_to: values.enrollment_date_to,
      practiceId: localStoragePracticeId ? localStoragePracticeId : "",
      //   enrolledProgram: values.enrolledProgram
      check: "Workspace"
    });
    return actions.resetMessage();
  }, []);
  //   const handleValuesChange = (e, name, value) => {
  //     console.log("name", name, value);
  //     if (value) {
  //       setValues({ ...values, [name]: value.value });
  //     } else {
  //       setValues({ ...values, [name]: "" });
  //     }
  //   };
  const handleSearchClick = (e, searchText) => {
    if (searchText) {
      const localStoragePracticeId = localStorage.getItem("practiceId");
      actions.getEnrollmentReport({
        enrollment_date_from: values.enrollment_date_from,
        enrollment_date_to: values.enrollment_date_to,
        practiceId: localStoragePracticeId ? localStoragePracticeId : "",
        patientSearch: searchText,
        //   enrolledProgram: values.enrolledProgram
        check: "Workspace"
      });
      return actions.resetMessage();
    }
    // setValues({
    //   ...values
    // });
    // actions.resetFinancialEstimateList();
  };

  const clearSearchClick = () => {
    // values.patientNameOrId = "";
    // setValues({ ...values });
  };

  function CustomToolbar() {
    return (
      <Grid container spacing={2} className="align-center">
        <Grid container style={{ width: "100%" }}>
          <Box sx={{ width: "100%" }} sm={3}>
            <QuickAdd handleSearchClick={handleSearchClick} clearSearchClick={clearSearchClick} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2.4} sm={1.5}>
              <Typography>Date From*</Typography>
            </Grid>
            <Grid item xs={2.4} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day", "year", "month"]}
                  disableFuture
                  value={values.enrollment_date_from}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, enrollment_date_from: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={2.4} sm={1.5}>
              <Typography>Date To*</Typography>
            </Grid>
            <Grid item xs={2.4} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day", "year", "month"]}
                  disableFuture
                  value={values.enrollment_date_to}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, enrollment_date_to: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={2.4}>
              <Button variant="contained" className="btn-usf" onClick={callAPI}>
                Run Report
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} alignItems="center" mt={1}> */}
          {/* Practice */}
          {/* <Grid item xs={12} sm={1.5}>
              <Typography>Practice</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={
                  practices.find((practice) => practice.practiceId === values?.practiceId)?.name
                }
                options={practices.map((item, index) => ({
                  id: index,
                  label: item.name,
                  value: item.practiceId
                }))}
                onChange={(e, value) => {
                  handleValuesChange(e, "practiceId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." size="small" />}
                fullWidth
              />
            </Grid> */}

          {/* Enrolled Program */}
          {/* <Grid item xs={12} sm={1.5}>
              <Typography>Program Type</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="enrolledProgram"
                value={ProgramType.find((pType) => pType.key === values?.enrolledProgram)?.value}
                options={
                  Object.keys(ProgramType).length > 0 &&
                  ProgramType?.map((programType) => ({
                    label: programType.value,
                    value: programType.key
                  }))
                }
                onChange={(e, value) => {
                  handleValuesChange(e, "enrolledProgram", value);
                }}
                renderInput={(params) => <TextField {...params} label="Program..." size="small" />}
                fullWidth
              />
            </Grid>*/}
          {/* <Grid item xs={1.5}>
            <Button variant="contained" className="btn-usf" onClick={callAPI}>
              Run Report
            </Button>
          </Grid> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    );
  }
  const columns = [
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      flex: 1,
      editable: false
    },
    {
      field: "personNumber",
      headerName: "PERSON #",
      flex: 1,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      flex: 1,
      editable: false
    },
    {
      field: "physicianLname",
      headerName: "PHYSICIANS",
      flex: 1,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "PROGRAM (SHORT NAME)",
      flex: 1,
      editable: false
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      flex: 1,
      editable: false,
      renderCell: ({ row }) =>
        row?.treatmentPlanCustomName != null ? row?.treatmentPlanCustomName : row?.treatmentPlan
    },
    {
      field: "enrollmentDate",
      headerName: "ENROLLMENT DATE",
      flex: 1,
      editable: false
    },
    {
      field: "currentStatus",
      headerName: "CURRENT STATUS",
      flex: 1,
      editable: false
    },
    {
      field: "currentStatusDate",
      headerName: "CURRENT STATUS DATE",
      flex: 1,
      editable: false
    }
  ];
  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
            headerText={`Enrollments From: ${values.enrollment_date_from} - To: ${values.enrollment_date_to}`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ enrollReportReducer /*practices*/ }) => {
  return {
    loading: enrollReportReducer.loading,
    enrollmentReportList: enrollReportReducer.enrollmentReportList,
    errorMsg: enrollReportReducer.error,
    successMsg: enrollReportReducer.success
    // practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEnrollmentReport: (payload) => {
      dispatch(actionTypeFromTrak.getEnrollmentReport(payload));
    },
    resetMessage: () => {
      dispatch(actionTypeFromTrak.resetMessage());
    },
    getPractices: () => {
      dispatch(getPractices());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeEnrollments);
