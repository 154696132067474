import React, { useState } from "react";
import { Box, Tab, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import PracticeOutcomes from "./PracticeOutcomes";
import PracticeEnrollments from "./PracticeEnrollments";
import InactiveParticipants from "./InacticeParticipants";
import ActiveParticipants from "./ActiveParticipants";
import TabPanel from "@mui/lab/TabPanel";

const WorkspaceReport = () => {
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box flexDirection="row">
      <TabContext value={tabValue}>
        <Box>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{ width: "100%" }}>
              <Tab sx={{ width: "25%" }} label="Practice Enrollments" value="1" />
              <Tab sx={{ width: "25%" }} label="Practice Outcomes" value="2" />
              <Tab sx={{ width: "25%" }} label="Inactive Participants" value="3" />
              <Tab sx={{ width: "25%" }} label="Active Participants" value="4" />
            </TabList>
          </Stack>
        </Box>
        <TabPanel value="1">{<PracticeEnrollments />}</TabPanel>
        <TabPanel value="2">{<PracticeOutcomes />}</TabPanel>
        <TabPanel value="3">{<InactiveParticipants />}</TabPanel>
        <TabPanel value="4">{<ActiveParticipants />}</TabPanel>
      </TabContext>
    </Box>
  );
};

export default WorkspaceReport;
