import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../appAuthencationCheck";

function* fetchEnrollmentReportList(action) {
  const { payload } = action;
  try {
    let params = {};
    if (payload?.check === "Workspace") {
      params = {
        enrollment_date_from: payload.enrollment_date_from || "",
        enrollment_date_to: payload.enrollment_date_to || "",
        practiceId: payload.practiceId || "",
        patientSearch: payload.patientSearch || ""
      };
    } else {
      params = {
        enrollment_date_from: payload.enrollment_date_from || "",
        enrollment_date_to: payload.enrollment_date_to || "",
        practiceId: payload.practiceId || "",
        programType: payload.enrolledProgram || "",
        patientSearch: payload.patientSearch || ""
      };
    }

    const requestBody = createRequestBody(
      "get",
      `/financial_snapshots/enrollments`,
      params,
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_ENROLLMENT_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_ENROLLMENT_REPORT_FAILED, errorMsg: error.message });
    }
  }
}

function* getEnrollmentReportSaga() {
  yield takeEvery(actionTypes.GET_ENROLLMENT_REPORT_REQUESTED, fetchEnrollmentReportList);
}
export default function* enrollReportSaga() {
  yield all([getEnrollmentReportSaga()]);
}
