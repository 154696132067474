import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox
} from "@mui/material";
import * as actionType from "../actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import CommonModal from "../../shared/components/CommonModal";
import DeleteModal from "../../shared/components/DeleteModal";
import Button from "@mui/material/Button";
import {
  transactionDetailsConfig,
  delRefundTransactionDetailsConfig,
  delPRTransactionDetailsConfig
} from "../../shared/constants/renderConfigs";
import MessageModal from "../../shared/components/MessageModal";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PatientPaymentsModal from "./modals/PatientPaymentsModal";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import isAccess from "../../shared/components/CheckUserRights";

const TransactionLog = (props) => {
  let initialData = {};
  const {
    transactionLog = [],
    feesList,
    deleteTransaction,
    editTransaction,
    error,
    success,
    actions,
    loading,
    paymentCreated,
    successMsg,
    medcaseType,
    medcaseHeader,
    patientPaymentDetails,
    successPaymentDetails,
    successFees,
    successRefund,
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT
  } = props;
  const [rows, setRows] = useState(props.transactionLog);
  const [isOpen, setIsOpen] = useState(false);
  const [isRefOpen, setIsRefOpen] = useState(false);
  const [isPrOpen, setIsPrOpen] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [isDeletePayment, setIsDeletePayment] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const isPNFT = isAccess("pnft");
  const isEFT = isAccess("eft");

  useEffect(() => {
    setRows(transactionLog);

    // add options ---
    /**
     *   const options = clinicalList.map((item) => {
    if (item.outcomeCategory != null) {
      return item.clinicalTreatmentNo;
    } else {
      return [];
    }
  });
     */
  }, [props.transactionLog]);
  useEffect(() => {
    if (error || success || successMsg) {
      setIsMessageModal(true);
    }
  }, [feesList, deleteTransaction, editTransaction]);
  useEffect(() => {
    actions.getTransactionLog({ medcaseId: props.medcaseId });
    actions.getMedcaseHeader({ medcaseId: props.medcaseId });
  }, []);

  useEffect(() => {
    if (
      paymentCreated ||
      addPatientPaymentCC.length ||
      addPatientPaymentCH.length ||
      addPatientPaymentLN.length ||
      addPatientPaymentWT.length ||
      successMsg ||
      successFees ||
      successRefund ||
      success
    ) {
      actions.getTransactionLog({ medcaseId: props.medcaseId });
    }
  }, [
    paymentCreated,
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT,
    successMsg,
    successFees,
    successRefund,
    success
  ]);

  useEffect(() => {
    if (successPaymentDetails) {
      setIsDeletePayment(true);
    }
  }, [successPaymentDetails]);
  // useEffect(() => {
  //   if (successMsg) {
  //     actions.getTransactionLog({ medcaseId: props.medcaseId });
  //   }
  // }, [successMsg]);
  const transactionLogColumns = [
    {
      field: "transaction",
      headerName: "TRANSACTION NO",
      width: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          color="primary"
          variant="text"
          className="word-wrap"
          onClick={() => {
            setTransactionId(row.transaction);
            // let amount =
            //   row && row.amount && "$" + (Math.round(row.amount / 100) * 100).toLocaleString();
            // let amount = row && row.amount && "$" + row.amount.toLocaleString();
            if (
              row.transactionType.toUpperCase() == "Program Refund".toUpperCase() ||
              row.transactionType.toUpperCase() == "Pregnancy Loss Refund".toUpperCase() ||
              row.transactionType.toUpperCase() == "Miscellaneous Refund".toUpperCase()
            ) {
              const obj = {
                refundType: row.transactionType,
                cycle: row?.cycle ? row.cycle : "",
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate,
                transactionDate: row.transactionDate,
                comment: row.comment,
                isImportant: row.isImportant,
                originalAmount: row.amount
              };
              setTransactionType("payment");
              setValues(obj);
              setIsRefOpen(true);
            } else if (
              row.transactionType.toUpperCase() == "Patient Payment".toUpperCase() ||
              row.transactionType.toUpperCase() == "Participant".toUpperCase()
            ) {
              setTransactionType("patient payment");
              actions.getPatientPaymentDetails({
                transactionId: row.transaction,
                medcaseId: props.medcaseId,
                paymentType: row.paymentType
              });
              setTransactionId(row.transaction);
              setPaymentType(row.paymentType);
            } else if (
              row.transactionType.toUpperCase() == "Practice".toUpperCase() ||
              row.transactionType.toUpperCase() == "Practice - Medical Cost Reserve".toUpperCase()
            ) {
              const obj = {
                transactionType: row.transactionType,
                cycle: row?.cycle ? row?.cycle : "",
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate,
                transactionDate: row.transactionDate,
                comment: row.comment,
                isImportant: row.isImportant,
                originalAmount: row.amount
              };
              setTransactionType("Practice Transaction Details");
              setValues(obj);
              setIsPrOpen(true);
            } else if (
              row.transactionType.toUpperCase() == "Completion Revenue".toUpperCase() ||
              row.transactionType.toUpperCase() == "Service Revenue".toUpperCase() ||
              row.transactionType.toUpperCase() == "Success Revenue".toUpperCase()
            ) {
              const obj = {
                refundType: row.transactionType,
                cycle: row?.cycle ? row.cycle : "",
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate,
                transactionDate: row.transactionDate,
                comment: row.comment,
                isImportant: row.isImportant,
                originalAmount: row.amount
              };
              setTransactionType("revenue");
              setValues(obj);
              setIsRefOpen(true);
            } else {
              console.log("inside");
              const obj = {
                feeType: row.transactionType,
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate,
                transactionDate: row.transactionDate,
                comment: row.comment,
                isImportant: row.isImportant,
                originalAmount: row.amount
              };
              setTransactionType("fee");
              setValues(obj);
              setIsOpen(true);
            }
          }}>
          {row.transaction}
        </Button>
      )
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        let amount = row && row.amount && "$" + priceFormat(row.amount);
        let amountSign = amount;
        if (row.negativeFlag) {
          amountSign = "(" + amount + ")";
        }
        return row.amount == null ? "0" : amountSign;
      }
    },
    // {
    //   field: "discountAmount",
    //   headerName: "LOAN DISCOUNT AMOUNT",
    //   width: 100,
    //   editable: false,
    //   flex: 1,
    //   renderCell: ({ row }) => {
    //     return row.discountAmount == null
    //       ? "0"
    //       : row && row.discountAmount && row.discountAmount.toLocaleString();
    //   }
    // },
    {
      field: "transactionType",
      headerName: "TRANSACTION TYPE",
      width: 75,
      editable: false,
      flex: 1
    },
    {
      field: "paymentType",
      headerName: "PAYMENT TYPE",
      width: 75,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.paymentType == null ? "--" : row.paymentType;
      }
    },
    {
      field: "Invoice",
      headerName: "INVOICE",
      width: 75,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.transactionType == "Patient Payment" || row.transactionType == "Participant") {
          row.Invoice = medcaseHeader.medcaseInvoice;
        } else {
          row.Invoice = "--";
        }
      }
    },
    {
      field: "transactionDate",
      headerName: "TRANSACTION DATE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) =>
        row.transactionDate ? moment(row.transactionDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "recordedDate",
      headerName: "RECORDED DATE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) =>
        row.recordedDate ? moment(row.recordedDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "user",
      headerName: "USER",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "isImportant",
      headerName: "COMMENT",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <Checkbox key={"row" + row.transaction} disabled checked={row.comment} />;
      }
    }
  ];
  const handleDeleteTransaction = (event) => {
    event.preventDefault();
    setDeleteModal(true);
    console.log("zhr here");
    //setTransactionId(row.transactionId);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const refundCloseModal = () => {
    setIsRefOpen(false);
  };
  const handleClose = () => {
    actions.resetGetPatientPayment();
    setIsDeletePayment(false);
  };
  const prCloseModal = () => {
    setIsPrOpen(false);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    setIsOpen(false);
    setIsRefOpen(false);
    setIsPrOpen(false);
    setIsDeletePayment(false);
    setTransactionType("");
    //actions.resetMessage();
  };
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    console.log("zhr: ", name, value);
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setIsPrOpen(false);
  };

  const onEditClick = () => {
    initialData = {
      transactionId: transactionId,
      type: transactionType,
      medcaseId: props.medcaseId,
      isPNFT: isPNFT,
      isEFT: isEFT,
      ...values
    };
    console.log("zhr initialData:", initialData);
    actions.editTransaction(initialData);
    closeModal();
  };
  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      transactionId: transactionId,
      type: transactionType
    };
    actions.deleteTransaction(initialData);
    closeDeleteModal();
    setIsOpen(false);
    setIsRefOpen(false);
    setIsDeletePayment(false);
  };
  const transaction = transactionDetailsConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    onEditClick: onEditClick
  });
  const delTransaction = delRefundTransactionDetailsConfig({
    values,
    onChange: handleInputChange,
    onClick: refundCloseModal,
    onEditClick: onEditClick
    // options: []
  });
  const delPrTransaction = delPRTransactionDetailsConfig({
    values,
    onChange: handleInputChange,
    onClick: prCloseModal,
    onEditClick: onEditClick
  });

  const savePatientPayment = (data) => {
    console.log("zhr:", data);
    data.amount = data.amount ? Number(data.amount) : 0;
    data.recordedDate = data.recordedDate ? moment(data.recordedDate).format("YYYY-MM-DD") : null;
    data.transactionDate = data.transactionDate
      ? moment(data.transactionDate).format("YYYY-MM-DD")
      : null;
    data.isPNFT = isPNFT;
    data.isEFT = isEFT;
    console.log("data==>", data);
    if (data.paymentType == "Credit Card") {
      delete data.paymentType;
      actions.editCreditCardPayment({
        medcaseId: props.medcaseId,
        data,
        transactionId: data?.transactionId
      });
    } else if (data.paymentType == "Check") {
      delete data.paymentType;
      actions.editCheckPayment({
        medcaseId: props.medcaseId,
        data,
        transactionId: data?.transactionId
      });
    } else if (data.paymentType == "Loan") {
      delete data.paymentType;
      actions.editLoanPayment({
        medcaseId: props.medcaseId,
        data,
        transactionId: data?.transactionId
      });
    } else if (data.paymentType == "Wire Transfer") {
      delete data.paymentType;
      actions.editWiredPayment({
        medcaseId: props.medcaseId,
        data,
        transactionId: data?.transactionId
      });
    }

    handleClose();
  };

  const handleChange = () => {
    if (expanded === true) {
      setExpanded(false);
    }
    if (expanded === false) {
      setExpanded(true);
    }
  };

  const deletePatientPayment = () => {
    setDeleteModal(true);
  };

  return (
    <>
      <Box className="m-8">
        <Accordion
          key="transactionLog"
          expanded={medcaseType != "PGTA" && expanded}
          onChange={handleChange}
          disabled={medcaseType == "PGTA"}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              flexDirection: "row-reverse"
            }}>
            <Stack direction="row" className="grid-header">
              <Typography>Transaction Log</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%"
              // minHeight:
              //   medcaseType == "PGTA"
              //     ? "200px"
              //     : rows
              //     ? rows.length > 2
              //       ? "400pX"
              //       : "200px"
              //     : "200px"
            }}>
            <DataGridPremiumComp
              rows={medcaseType == "PGTA" ? [] : rows}
              getRowId={() => uuidv4()}
              rowCount={medcaseType == "PGTA" ? 0 : rows.length}
              columns={transactionLogColumns}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
              getRowClassName={(params) => {
                return params.row.isImportant ? "imp-trans" : "";
              }}
              autoHeight={true}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <CommonModal open={isOpen} config={transaction} handleSubmit={handleDeleteTransaction} />
      <CommonModal
        open={isRefOpen}
        config={delTransaction}
        handleSubmit={handleDeleteTransaction}
      />
      <CommonModal
        open={isPrOpen}
        config={delPrTransaction}
        handleSubmit={handleDeleteTransaction}
      />
      <DeleteModal
        text={
          "Are you sure you want to delete this transaction ? *WARNING* It cannot be recovered!"
        }
        open={deleteModal}
        id={transactionId}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        open={isMessageModal}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
      <PatientPaymentsModal
        open={isDeletePayment}
        paymentType={paymentType}
        patientPaymentDetails={patientPaymentDetails}
        isDelete={isDeletePayment}
        handleClose={handleClose}
        savePatientPayment={savePatientPayment}
        deletePayment={deletePatientPayment}
      />
    </>
  );
};

const mapStateToProps = ({
  transactionLogReducer,
  addRevenueReducer,
  statusReducer,
  patientPaymentReducer,
  manualRefundReducer
}) => {
  return {
    transactionLog: transactionLogReducer.transactionLog,
    medcaseHeader: statusReducer.medcaseHeader,
    loading: transactionLogReducer.loading,
    error: transactionLogReducer.error,
    success: transactionLogReducer.success,
    feesList: transactionLogReducer.feesList,
    deleteTransaction: transactionLogReducer.deleteTransaction,
    editTransaction: transactionLogReducer.editTransaction,
    successMsg: addRevenueReducer.success,
    patientPaymentDetails: patientPaymentReducer.paymentDetails,
    successPaymentDetails: patientPaymentReducer.successPaymentDetails,

    addPatientPaymentCC: patientPaymentReducer.addPatientPaymentCC,
    addPatientPaymentLN: patientPaymentReducer.addPatientPaymentLN,
    addPatientPaymentCH: patientPaymentReducer.addPatientPaymentCH,
    addPatientPaymentWT: patientPaymentReducer.addPatientPaymentWT,
    successFees: transactionLogReducer.successFees,
    successRefund: manualRefundReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getTransactionLog: (payload) => {
      dispatch(actionType.getTransactionLog(payload));
    },
    deleteTransaction: (payload) => {
      dispatch(actionType.deleteTransaction(payload));
    },
    editTransaction: (payload) => {
      dispatch(actionType.editTransaction(payload));
    },

    resetTransactionLog: () => {
      dispatch(actionType.resetTransactionLog());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getMedcaseHeader: (payload) => {
      dispatch(actionType.getMedcaseHeader(payload));
    },
    getPatientPaymentDetails: (payload) => {
      dispatch(actionType.getPatientPaymentDetails(payload));
    },
    resetGetPatientPayment: () => {
      dispatch(actionType.resetGetPatientPayment());
    },
    editCreditCardPayment: (payload) => {
      dispatch(actionType.editCreditCardPayment(payload));
    },
    editCheckPayment: (payload) => {
      dispatch(actionType.editCheckPayment(payload));
    },
    editLoanPayment: (payload) => {
      dispatch(actionType.editLoanPayment(payload));
    },
    editWiredPayment: (payload) => {
      dispatch(actionType.editWiredPayment(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TransactionLog);
