import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchInactiveParticipants(action) {
  const { payload } = action;
  try {
    const params = {
      // offset: "",
      // limit: "100",
      participantType: payload.participantType,
      practiceId: payload.practiceId,
      patientSearch: payload.patientSearch || ""
    };
    const requestBody = createRequestBody(
      "get",
      `/financial_snapshots/participant-reports`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_INACTIVE_PARTICIPANTS_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_INACTIVE_PARTICIPANTS_REPORT_FAILED,
        errorMsg: error.message
      });
    }
  }
}

function* getInactiveParticipantReportSaga() {
  yield takeEvery(
    actionTypes.GET_INACTIVE_PARTICIPANTS_REPORT_REQUESTED,
    fetchInactiveParticipants
  );
}
export default function* inactiveParticipantReportSaga() {
  yield all([getInactiveParticipantReportSaga()]);
}
