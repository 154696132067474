import React, { useEffect, useState } from "react";
import { Box, Stack, TextField, Grid, Typography, Button, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as actionType from "../actions";
import { connect } from "react-redux";
import moment from "moment";
//import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import { Link } from "react-router-dom";
import { getPractices } from "../../admin/actions";

const EnrollmentReport = (props) => {
  const formData = {
    enrollment_date_from: moment().startOf("month").format("YYYY-MM-DD"),
    enrollment_date_to: moment().format("YYYY-MM-DD"),
    practiceId: null,
    enrolledProgram: "ALL"
  };
  const ProgramType = [
    { key: "All", value: "All" },
    { key: "REFUND", value: "IVF Refund" },

    { key: "NON_REFUND", value: "IVF Non Refund" },
    { key: "PGTA", value: "PGTA" }
  ];
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const { enrollmentReportList = [], practices, actions, loading } = props;
  const callAPI = () => {
    actions.getEnrollmentReport({
      enrollment_date_from: values.enrollment_date_from,
      enrollment_date_to: values.enrollment_date_to,
      practiceId: values.practiceId,
      enrolledProgram: values.enrolledProgram
    });
  };
  const rows = enrollmentReportList.map((item, index) => {
    item.id = index;
    return item;
  });
  useEffect(() => {
    actions.getPractices();
    return actions.resetMessage();
  }, []);
  const handleValuesChange = (e, name, value) => {
    console.log("name", name, value);
    if (value) {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: "" });
    }
  };

  function CustomToolbar() {
    return (
      <Grid container spacing={2} className="align-center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={1.5}>
              <Typography>Date From*</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day", "year", "month"]}
                  disableFuture
                  value={values.enrollment_date_from}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, enrollment_date_from: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Typography>Date To*</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day", "year", "month"]}
                  disableFuture
                  value={values.enrollment_date_to}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, enrollment_date_to: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" mt={1}>
            {/* Practice */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Practice</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={
                  practices.find((practice) => practice.practiceId === values?.practiceId)?.name
                }
                options={practices.map((item, index) => ({
                  id: index,
                  label: item.name,
                  value: item.practiceId
                }))}
                onChange={(e, value) => {
                  handleValuesChange(e, "practiceId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." size="small" />}
                fullWidth
              />
            </Grid>

            {/* Enrolled Program */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Program Type</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="enrolledProgram"
                value={ProgramType.find((pType) => pType.key === values?.enrolledProgram)?.value}
                options={
                  Object.keys(ProgramType).length > 0 &&
                  ProgramType?.map((programType) => ({
                    label: programType.value,
                    value: programType.key
                  }))
                }
                onChange={(e, value) => {
                  handleValuesChange(e, "enrolledProgram", value);
                }}
                renderInput={(params) => <TextField {...params} label="Program..." size="small" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={1.5}>
              <Button variant="contained" className="btn-usf" onClick={callAPI}>
                Run Report
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  const columns = [
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      flex: 1,
      editable: false
    },
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      flex: 1,
      editable: false,
      renderCell: ({ row }) => (
        <Link
          style={{
            margin: "1rem",
            textDecoration: "none",
            color: "#1BA3AD",
            fontFamily: "Rubik",
            fontWeight: 500
          }}
          target="_blank"
          to={{
            pathname: `/trak/caselookup/caselist/${row.medcaseId}/status`,
            state: { data: row.medcaseId }
          }}>
          {row.medcaseId}
        </Link>
      )
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      flex: 1,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "PROGRAM (SHORT NAME)",
      flex: 1,
      editable: false
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      flex: 1,
      editable: false,
      renderCell: ({ row }) =>
        row?.treatmentPlanCustomName != null ? row?.treatmentPlanCustomName : row?.treatmentPlan
    },
    {
      field: "enrollmentDate",
      headerName: "ENROLLMENT DATE",
      flex: 1,
      editable: false
    },
    {
      field: "currentStatus",
      headerName: "CURRENT STATUS",
      flex: 1,
      editable: false
    },
    {
      field: "currentStatusDate",
      headerName: "CURRENT STATUS DATE",
      flex: 1,
      editable: false
    },
    {
      field: "estimateCreatedBy",
      headerName: "EST CREATED BY",
      flex: 1,
      editable: false
    }
  ];
  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
            headerText={`Finanacial Snapshot 606 All Start Date: ${values.from} End Date: ${values.to}`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ enrollReportReducer, practices }) => {
  return {
    loading: enrollReportReducer.loading,
    enrollmentReportList: enrollReportReducer.enrollmentReportList,
    errorMsg: enrollReportReducer.error,
    successMsg: enrollReportReducer.success,
    practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEnrollmentReport: (payload) => {
      dispatch(actionType.getEnrollmentReport(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getPractices: () => {
      dispatch(getPractices());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentReport);
