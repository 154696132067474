import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, InputLabel, Grid, Button } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import * as actionTypeEstimate from "../../workspace/actions";
import MessageModal from "../../shared/components/MessageModal";

const MedcaseCard = (props) => {
  const { actions, patientDetails, medcaseDetails, addStatus, successEnroll, miscellaneousData } =
    props;
  console.log("MedcaseCard medcaseDetails: ", medcaseDetails);

  const [estimateNumber, setEstimateNumber] = useState([]);

  const [isAlertIssue, setIsAlertIssue] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  useEffect(() => {
    if (["Enrolled", "Re-Enrolled"].includes(medcaseDetails?.medcaseStatus)) {
      const currentDate = moment();
      const contractExpiryDate = moment(medcaseDetails?.contractExpiryDate);
      const dateRangeStart = contractExpiryDate.clone().subtract(30, "days");
      console.log("zhr Current Date:", currentDate.format("YYYY-MM-DD"));
      console.log("zhr Contract Expiry Date:", contractExpiryDate.format("YYYY-MM-DD"));
      console.log("zhr Date Range Start:", dateRangeStart.format("YYYY-MM-DD"));
      if (currentDate.isBetween(dateRangeStart, contractExpiryDate, "day", "[]")) {
        setIsAlertIssue(true);
        setAlertMsg("Warning: Contract is due to expire in the next 30 days.");
      } else if (currentDate.isSameOrAfter(contractExpiryDate, "day")) {
        setIsAlertIssue(true);
        setAlertMsg("Warning: Contract has expired.");
      } else {
        setIsAlertIssue(false);
        setAlertMsg("");
      }
    }
  }, [medcaseDetails]);

  let { id } = useParams();
  useEffect(() => {
    /* const practice_Id = */ sessionStorage.setItem("practiceId", medcaseDetails.practiceId);
    actions.getMedcaseDetailsHeader({ medcaseId: id });
    // console.log(practice_Id);
  }, [addStatus, successEnroll, miscellaneousData]);

  function formateDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    if (medcaseDetails?.estimateId) {
      setEstimateNumber(medcaseDetails?.estimateId.split("-"));
    }
  }, [medcaseDetails]);

  return (
    <>
      <Box className="m-10" sx={{ background: "#F9F9F9" }}>
        <Stack direction="row" className="pad-10 flex-between">
          <Typography sx={{ fontWeight: "bold" }}>Medcase Details: </Typography>
        </Stack>
        <Grid container className="pad-10">
          <Grid item xs={2.4}>
            <Stack direction="column">
              <InputLabel>Medcase </InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.medcaseId ? medcaseDetails.medcaseId : "--"}
              </Typography>
              <InputLabel>Practice</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.practice ? medcaseDetails.practice : "--"}
              </Typography>
              <InputLabel>Program</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.program ? medcaseDetails.program : "--"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.4}>
            <Stack direction="column">
              <InputLabel>IVF Medcase Status</InputLabel>
              <Typography
                style={{ marginBottom: 5 }}
                className={
                  ["Enrolled", "Re-Enrolled"].indexOf(medcaseDetails?.medcaseStatus) > -1
                    ? "red-bold"
                    : null
                }>
                {medcaseDetails?.medcaseStatus === null ? "--" : medcaseDetails?.medcaseStatus}
              </Typography>
              <InputLabel>Location</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails.location === null ? "--" : medcaseDetails.location}
              </Typography>
              <InputLabel>Treatment Plan</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.treatmentPlanCustomName !== null
                  ? medcaseDetails?.treatmentPlanCustomName
                  : medcaseDetails.treatmentPlan}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.4}>
            <Stack direction="column">
              <InputLabel>Medcase Date</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails.medcaseDate === null
                  ? "--"
                  : medcaseDetails.medcaseDate && moment(medcaseDetails.medcaseDate).format("L")}
              </Typography>
              <InputLabel>Location Contact</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails.locationContactFirstName != null &&
                medcaseDetails.locationContactLastName != null
                  ? medcaseDetails.locationContactFirstName +
                    " " +
                    medcaseDetails.locationContactLastName
                  : "--"}
              </Typography>
              <InputLabel>Contract Exp Date</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails.contractExpiryDate === null
                  ? "--"
                  : medcaseDetails.contractExpiryDate &&
                    moment(medcaseDetails.contractExpiryDate).format("L")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.4}>
            <Stack direction="column">
              <InputLabel>Medcase Active/InActive</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails.medcaseActive === null ? "--" : medcaseDetails.medcaseActive}
              </Typography>
              <InputLabel>Physician</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails.physicianFirstName != null &&
                medcaseDetails.physicianLastName != null
                  ? medcaseDetails.physicianFirstName + " " + medcaseDetails.physicianLastName
                  : "--"}
              </Typography>
              <InputLabel>PGTA Medcase Status</InputLabel>
              <Typography
                style={{ marginBottom: 5 }}
                className={
                  ["Enrolled", "Re-Enrolled"].indexOf(medcaseDetails.pgtaAnalysis) > -1
                    ? "red-bold"
                    : null
                }>
                {medcaseDetails.pgtaAnalysis === null ? "--" : medcaseDetails.pgtaAnalysis}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.4}>
            <Stack direction="column">
              <InputLabel>Detailed Estimate#</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.estimateId ? (
                  <Link
                    target="_blank"
                    to={`/workspace/financial-estimates/${patientDetails.personId}?estimateId=${medcaseDetails?.estimateId}`}>
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        let firstName = patientDetails?.patientFirstName;
                        let lastName = patientDetails?.patientLastName;
                        let patientName = firstName + " " + lastName;
                        localStorage.setItem("patientName", patientName);
                        localStorage.setItem("practiceId", patientDetails?.practiceId);
                        let payload = {
                          practiceId: patientDetails?.practiceId,
                          personId: patientDetails?.personId
                        };
                        console.log("medcaseDetails?.estimateId:", medcaseDetails?.estimateId);
                        console.log("payload:", payload);
                        actions.setEstimateId(medcaseDetails?.estimateId);
                        actions.resetEstimateViewList();
                        // actions.inputValues(values);
                        actions.getFinancialEstimateViewList(payload);
                      }}>
                      <span style={{ textDecoration: "underline" }}>
                        {estimateNumber && estimateNumber[1] ? estimateNumber[1] : "--"}
                      </span>
                    </Button>
                  </Link>
                ) : (
                  "--"
                )}
              </Typography>
              <InputLabel>EstimateCreateDate</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.estimateCretedDate
                  ? formateDate(medcaseDetails.estimateCretedDate)
                  : "--"}
              </Typography>
              <InputLabel>Estimate Published Date</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {medcaseDetails?.estimatePublishDate
                  ? formateDate(medcaseDetails.estimatePublishDate)
                  : "--"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <MessageModal
        open={isAlertIssue}
        message={alertMsg}
        closeModal={() => {
          setAlertMsg("");
          setIsAlertIssue(false);
        }}
      />
    </>
  );
};

const mapStateToProps = ({
  medcaseHeaderReducer,
  statusReducer,
  enrollReducer,
  caseUpdateReducer
}) => {
  return {
    patientDetails: medcaseHeaderReducer.patientDetails,
    medcaseDetails: medcaseHeaderReducer.medcaseDetails,
    loading: medcaseHeaderReducer.loading,
    errorMsg: medcaseHeaderReducer.error,
    addStatus: statusReducer.addStatus,
    successEnroll: enrollReducer.success,
    miscellaneousData: caseUpdateReducer.miscellaneousData
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMedcaseDetailsHeader: (payload) => {
      dispatch(actionType.getMedcaseDetailsHeader(payload));
    },
    resetEstimateViewList: () => {
      dispatch(actionTypeEstimate.resetEstimateViewList());
    },
    getFinancialEstimateViewList: (payload) => {
      dispatch(actionTypeEstimate.getFinancialEstimateViewList(payload));
    },
    inputValues: (payload) => {
      dispatch(actionTypeEstimate.inputValues(payload));
    },
    setEstimateId: (payload) => {
      dispatch(actionTypeEstimate.setEstimateId(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MedcaseCard);
