import * as actionType from "./ActionTypes";

export const getFinancialEstimateQuestions = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_QUESTIONS_REQUESTED,
    payload
  };
};

export const getTreatmentPlanQuestions = (payload) => {
  return {
    type: actionType.GET_TREATMENT_QUESTIONS_REQUESTED,
    payload
  };
};

export const deleteEstimateTreatmentPlan = (payload) => {
  return {
    type: actionType.DELETE_ESTIMATE_TREATMENT_PLAN_REQUESTED,
    payload: payload
  };
};

export const createEstimate = (payload) => {
  return {
    type: actionType.CREATE_FINANCIAL_ESTIMATE_REQUESTED,
    payload
  };
};

export const addTreatmentPlan = (payload) => {
  return {
    type: actionType.CREATE_TREATMENT_PLAN_REQUESTED,
    payload
  };
};

export const editTreatmentPlan = (payload) => {
  return {
    type: actionType.EDIT_TREATMENT_PLAN_REQUESTED,
    payload
  };
};

export const getFinancialEstimateList = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_LIST_REQUESTED,
    payload
  };
};

export const getFinancialEstimateViewList = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_VIEW_LIST_REQUESTED,
    payload
  };
};

export const getNotes = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_NOTES_REQUESTED,
    payload
  };
};

export const updateNotes = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_ADD_NOTES_REQUESTED,
    payload
  };
};

export const getStages = (payload) => {
  return {
    type: actionType.ESTIMATE_STAGE_LIST_REQUESTED,
    payload
  };
};

export const getNecessary = (payload) => {
  return {
    type: actionType.NECESSARY_LIST_REQUESTED,
    payload
  };
};

export const getOptional = (payload) => {
  return {
    type: actionType.OPTIONAL_LIST_REQUESTED,
    payload
  };
};

export const addAdjustments = (payload) => {
  return {
    type: actionType.ADD_ADJUSTMENTS_REQUESTED,
    payload
  };
};

export const getAdjustments = (payload) => {
  return {
    type: actionType.GET_ADJUSTMENTS_REQUESTED,
    payload
  };
};

export const getEstimateCard = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_CARDS_REQUESTED,
    payload
  };
};

export const getSystemConfigurationList = (payload) => {
  return {
    type: actionType.SYSTEM_CONFIGURATION_VIEW_LIST_REQUESTED,
    payload
  };
};

export const resetEstimateCards = () => {
  return {
    type: actionType.RESET_ESTIMATE_CARDS
  };
};

export const resetAddTreatment = () => {
  return {
    type: actionType.RESET_ADD_TREATMENT
  };
};
export const resetAddTreatmentEstimate = () => {
  return {
    type: actionType.RESET_ADD_ESTIMATES
  };
};

export const resetFinancialEstimateList = () => {
  return {
    type: actionType.RESET_FINANCIAL_ESTIMATE_LIST
  };
};
export const resetCostCoverage = () => {
  return {
    type: actionType.RESET_COST_COVERAGE_MESSAGE
  };
};
export const getFertilityAccessPrograms = (payload) => {
  return {
    type: actionType.FERTILTY_ACCESS_PROGRAMS_REQUESTED,
    payload
  };
};

export const getProgramsList = (payload) => {
  return {
    type: actionType.PROGRAM_LIST_VISUALIZATION_REQUESTED,
    payload: payload
  };
};

// get partner Data
export const getPartnerData = (payload) => {
  return {
    type: actionType.GET_PARTNER_DETAIL_REQUESTED,
    payload
  };
};

//refund application
export const getRefundQstns = () => {
  return {
    type: actionType.REFUND_QUESTIONS_REQUESTED
  };
};
export const getPersonalData = (payload) => {
  return {
    type: actionType.PERSONAL_DATA_IS_REQUESTED,
    payload: payload
  };
};
//publish to patient portal
export const publishToPatientPortal = (payload) => {
  return {
    type: actionType.PUBLISH_REQUESTED,
    payload
  };
};

//withdraw refund application
export const withdrawRefundApplication = (payload) => {
  return {
    type: actionType.WITHDRAW_REFUND_APP_REQUESTED,
    payload
  };
};

// get fapList
export const getFapList = (payload) => {
  return {
    type: actionType.FAP_LIST_REQUESTED,
    payload: payload
  };
};

// get PracticeData
export const getPersonDataById = (payload) => {
  return {
    type: actionType.GET_PERSON_BY_ID,
    payload: payload
  };
};

//reset fapList
export const resetFapList = () => {
  return {
    type: actionType.RESET_FAP_LIST
  };
};

export const getPracticeUsers = (payload) => {
  return {
    type: actionType.PRACTICE_USERS_REQUESTED,
    payload
  };
};

//save asignee
export const saveAsignee = (payload) => {
  return {
    type: actionType.ASIGNEE_APP_REQUESTED,
    payload
  };
};

//resetmessage
export const resetstages = () => {
  return {
    type: actionType.RESET_STAGES
  };
};
//resetmessage
export const resetOptional = () => {
  return {
    type: actionType.RESET_OPTIONAL
  };
};
//resetmessage
export const resetNecessary = () => {
  return {
    type: actionType.RESET_NECESSARY
  };
};
//resetmessage
export const resetFapVisibilityData = () => {
  return {
    type: actionType.RESET_FAP_VISIBILITY,
    success: false,
    error: false
  };
};
export const resetMessage = () => {
  return {
    type: actionType.RESET_MESSAGE,
    success: false,
    error: false
  };
};

export const resetNoteMessage = () => {
  return {
    type: actionType.RESET_NOTE_MESSAGE,
    successNote: "",
    error: false
  };
};
export const resetMessageModal = () => {
  return {
    type: actionType.RESET_MESSAGE_MODAL,
    successAddEstimate: false,
    errorAddEstimate: false
  };
};

export const cardClickedAction = () => {
  return {
    type: actionType.CARD_CLICK_ACTION
  };
};

export const resetCardClick = () => {
  return {
    type: actionType.RESET_CARD_CLICK
  };
};

export const inputValues = (payload) => {
  return {
    type: actionType.INPUT_VALUES,
    payload
  };
};

export const resetRefundQuestions = () => {
  return {
    type: actionType.RESET_REFUND_QUESTIONS
  };
};

export const saveRefundApplication = (payload) => {
  return {
    type: actionType.SAVE_REFUND_APP_REQUESTED,
    payload
  };
};
export const saveDraftRefundApplication = (payload) => {
  return {
    type: actionType.SAVE_DRAFT_REFUND_APP_REQUESTED,
    payload
  };
};

export const FapRowData = (payload) => {
  return {
    type: actionType.FAP_ROW_DATA_REQUESTED,
    payload
  };
};

export const resetWithdraw = () => {
  return {
    type: actionType.RESET_WITHDRAW_RESPONSE
  };
};

export const resetAssignee = () => {
  return {
    type: actionType.RESET_ASSIGNEE_RESPONSE
  };
};

export const resetPublishMessage = () => {
  return {
    type: actionType.RESET_PUBLISH_MSG
  };
};

export const updateAdjustment = (payload) => {
  return {
    type: actionType.UPDATE_ADJUSTMENTS_REQUESTED,
    payload
  };
};
export const getEditProgramFapVisibility = (payload) => {
  return {
    type: actionType.EDIT_PROGRAM_FAP_VISIBILITY_REQUESTED,
    payload
  };
};

export const resetEstimateViewList = () => {
  return {
    type: actionType.RESET_FINANCIAL_ESTIMATE_VIEW_LIST
  };
};

export const resetSaveRefundApp = () => {
  return {
    type: actionType.RESET_SAVE_REFUND_APP
  };
};

export const resetSubmit = () => {
  return {
    type: actionType.RESET_SUBMIT_REFUND_APP
  };
};

export const getRefundQuestionResponse = (payload) => {
  return {
    type: actionType.GET_REFUND_RESPONSE_REQUESTED,
    payload
  };
};

export const resetRefundResponses = () => {
  return {
    type: actionType.RESET_REFUND_RESPONSE
  };
};

export const updateOutcomeFormDetails = (payload) => {
  return {
    type: actionType.UPDATE_CLINICAL_TREATMENT_OUTCOME_DATA_REQUESTED,
    payload
  };
};

export const saveEditedAuthForm = (payload) => {
  return {
    type: actionType.EDITED_AUTH_DATA_SAVE_REQUESTED,
    payload
  };
};

export const getTreatmentPlanList = (payload) => {
  return {
    type: actionType.GET_TREATMENT_PLAN_LIST_REQUESTED,
    payload
  };
};
export const getPatientMedcaseInfo = (payload) => {
  return {
    type: actionType.GET_MEDCASE_PATIENT_INFO_REQUESTED,
    payload
  };
};

export const getClinicalData = (payload) => {
  return {
    type: actionType.CLINICAL_TREATMENT_DATA_REQUESTED,
    payload
  };
};

export const resetUpdateAdjustments = () => {
  return {
    type: actionType.RESET_UPDATE_ADJUSTMENTS
  };
};
export const addFapVisibility = (payload) => {
  return {
    type: actionType.ADD_FAP_VISIBILITY_REQUESTED,
    payload
  };
};
export const resetGetQstns = () => {
  return {
    type: actionType.RESET_GET_QSTNS
  };
};

export const editTreatment = () => {
  return {
    type: actionType.EDIT_TREATMENT
  };
};

export const resetEditTreatment = () => {
  return {
    type: actionType.RESET_EDIT_TREATMENT
  };
};

export const setEstimateId = (payload) => {
  return {
    type: actionType.SET_ESTIMATE_ID,
    payload
  };
};

export const approveDenyRefundApplication = (payload) => {
  return {
    type: actionType.APPROVE_DENY_REFUND_APPLICATION_REQUESTED,
    payload
  };
};

export const resetPartnerData = () => {
  return {
    type: actionType.RESET_PARTNER_DATA
  };
};

export const getEstimatePDFData = (payload) => {
  return {
    type: actionType.ESTIMATED_LIST_PDF_REQUESTED,
    payload
  };
};
