import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPractices() {
  try {
    // query params
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("get", `/practices`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("fetch response: " + JSON.stringify(response));
    yield put({
      type: actionTypes.PRACTICE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PRACTICE_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getPracticeSaga() {
  yield takeEvery(actionTypes.PRACTICE_LIST_REQUESTED, fetchPractices);
}

function* createNewPractice(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("post", `/practices`, params, payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("createNewPractice response: ", response);
    yield put({
      type: actionTypes.ADD_NEW_PRACTICE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_PRACTICE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewPracticeSaga() {
  yield takeEvery(actionTypes.ADD_NEW_PRACTICE_REQUESTED, createNewPractice);
}

function* editPractice(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}`,
      params,
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("edit practice:", response);
    yield put({
      type: actionTypes.EDIT_PRACTICE_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PRACTICE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editPracticeSaga() {
  yield takeEvery(actionTypes.EDIT_PRACTICE_REQUESTED, editPractice);
}

function* exportPracticeData(action) {
  const { payload } = action;
  console.log(payload, "Payload Export");
  const params = {
    offset: "",
    limit: "100"
  };
  try {
    const requestBody = createRequestBody("get", `/practices/export/${payload.practiceId}`, params);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    const jsonString = JSON.stringify(response.data, null, 2); // Pretty format with indentation

    // Create a Blob object with the JSON data
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `export-practice.txt`; // The name of the file to download

    // Trigger the download
    document.body.appendChild(link); // Append the link to the body (not visible)
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Clean up by removing the link element
    // console.log("edit practice:", response);
    // yield put({
    //   // type: actionTypes.EDIT_PRACTICE_SUCCESS,
    //   // payload: response.data,
    //   // successMsg: MSG.UPDATED_SUCCESSFULLY
    // });
  } catch (err) {
    console.log(err);
    // if (appAuthencationCheck(err) === true) {
    //   yield put({
    //     type: actionTypes.EDIT_PRACTICE_FAILED,
    //     errorMsg: err.response.data
    //   });
    // }
  }
}

function* exportPracticeDataDetails() {
  yield takeEvery(actionTypes.EXPORT_PRACTICE_DATA, exportPracticeData);
}

function* importPracticeData(action) {
  const { payload } = action;
  console.log(payload, "Payload Import");
  const params = {
    offset: "",
    limit: "100"
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/importPractice/34534`,
      params,
      payload.url
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    if (response.data == "import string failed") {
      yield put({
        type: actionTypes.IMPORT_PRACTICE_FAILED,
        errorMsg: response.data
      });
    } else {
      yield put({
        type: actionTypes.IMPORT_PRACTICE_DATA_SUCCESS,
        payload: response.data,
        successMsg: "The practice is being created.You will be notified as done."
      });
    }
  } catch (err) {
    // if (appAuthencationCheck(err) === true) {
    yield put({
      type: actionTypes.IMPORT_PRACTICE_FAILED,
      errorMsg: err.response.data
    });
    // }
  }
}

function* importPracticeDataDetails() {
  yield takeEvery(actionTypes.IMPORT_PRACTICE_DATA, importPracticeData);
}

function* postFile(action) {
  const { payload } = action;
  const { fileEncoded, practiceId, fileType, fileName } = payload;
  try {
    const params = {
      practiceId: practiceId,
      isBase64Encoded: true,
      fileName: fileName
    };
    const requestBody = createRequestBody("post", `/fileUpload`, params, fileEncoded);
    const request = signatureV4CreateCanonicalRequest(
      requestBody,
      getTemporaryCredentials(),
      fileType
    );
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.POST_FILE_SUCCESS,
      payload: response.data,
      successMsg: "file send successfully"
    });
  } catch (err) {
    yield put({
      type: actionTypes.POST_FILE_FAILED,
      errorMsg: err.response
    });
  }
}

function* postFileSaga() {
  yield takeEvery(actionTypes.POST_FILE_REQUESTED, postFile);
}

export default function* practiceSaga() {
  yield all([
    getPracticeSaga(),
    addNewPracticeSaga(),
    editPracticeSaga(),
    exportPracticeDataDetails(),
    importPracticeDataDetails(),
    postFileSaga()
  ]);
}
